import {useDataSet} from "@utils/useDataSet";
import {FilterId, useFilters} from "@components/filters/FiltersContext";

const percentile = 85

export type IssueMeasureSnapshotData = {
    leadTimeDays: number
    cycleTimeDays: number
    issueCount: number
    completedCount: number
    completedEstimate: number
    totalEstimate: number
    leadTimeForChangesDays: number
    percentile: number
}

export type IssueMeasureDynamicsData = {
    percentile: number
    items: IssueMeasureDynamicsDataItem[]
}

export type IssueMeasureDynamicsDataItem = {
    ts: number
    leadTimeDays: number
    cycleTimeDays: number
    issueCount: number
    completedCount: number
    completedEstimate: number
    leadTimeForChangesDays: number
}

export function useIssueMeasureSnapshotDataSet() {
    const filters = useFilters()

    const {data, ...queryResult} = useDataSet({
        dataset: 'issue',
        filters: {
            ...filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes, FilterId.Teams]),
            completedOnly: true
        },
        select: [
            {name: 'issues_count'},
            {name: 'completed_issues_count'},
            {name: 'total_estimate'},
            {name: 'completed_estimate'},
            {name: 'issue_lead_time_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
            {name: 'issue_cycle_time_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
            {name: 'issue_lead_time_for_changes_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
        ]
    })

    if (data) {
        const snapshot: IssueMeasureSnapshotData = {
            issueCount: data.data[0][0] as number,
            completedCount: data.data[0][1] as number,
            totalEstimate: data.data[0][2] as number,
            completedEstimate: data.data[0][3] as number,
            leadTimeDays: data.data[0][4] as number,
            cycleTimeDays: data.data[0][5] as number,
            leadTimeForChangesDays: data.data[0][6] as number,
            percentile: percentile,
        }

        return {data: snapshot, ...queryResult}
    }

    return {data: undefined, ...queryResult}
}

export function useIssueMeasureDynamicsDataSet() {
    const filters = useFilters()

    const {data, ...queryResult} = useDataSet({
        dataset: 'issue',
        filters: {
            ...filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes, FilterId.Teams]),
            completedOnly: true
        },
        select: [
            {name: 'issue_resolved_at_trunc', alias: 'ts', args: [{name: 'trunc_to', value: 'month'}]},
            {name: 'issues_count'},
            {name: 'completed_issues_count'},
            {name: 'completed_estimate'},
            {name: 'issue_lead_time_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
            {name: 'issue_cycle_time_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
            {name: 'issue_lead_time_for_changes_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
        ]
    })

    if (data && data.data.length > 0) {
        const ds: IssueMeasureDynamicsData = {
            percentile,
            items: data.data.map(x => ({
                ts: x[0] as number,
                issueCount: x[1] as number,
                completedCount: x[2] as number,
                completedEstimate: x[3] as number,
                leadTimeDays: x[4] as number,
                cycleTimeDays: x[5] as number,
                leadTimeForChangesDays: x[6] as number,
            } as IssueMeasureDynamicsDataItem))
        }

        return {data: ds, isEmpty: false, ...queryResult}
    }

    return {data: undefined, isEmpty: data && data.data.length === 0, ...queryResult}
}
