import './DashboardPage.css'
import {Card, Col, Grid} from "@tremor/react";
import BurnDownChartWithOptions from "@components/charts/workScopeForecast/BurnDownChartWithOptions";
import {LeadTimeDynamicsChart} from "@components/charts/valueDelivery/IssueMeasureDynamicsChart";
import LeadTimeDistributionChart from "@components/charts/valueDelivery/LeadTimeDistributionChart";
import {CompletedIssuesTableView} from "@components/charts/valueDelivery/CompletedIssuesTableView";
import {SprintMeasurePlanFactChart} from "@components/charts/sprints/SprintMeasurePlanFactChart";
import {DefectsReportedFixedChart} from "@components/charts/defects/DefectsReportedFixedChart";
import {DefectsReportedFixedCumChart} from "@components/charts/defects/DefectsReportedFixedCumChart";
import {StatusBreakdownSummaryTable} from "@components/charts/flowAnalisys/StatusBreakdownSummaryTable";
import {StatusTimeBreakdownSummaryChart} from "@components/charts/flowAnalisys/StatusTimeBreakdownSummaryChart";
import {CumulativeFlowDiagramChart} from "@components/charts/flowAnalisys/CumulativeFlowDiagramChart";
import {
    CompletedEstimateSnapshotView,
    CompletedIssuesSnapshotView,
    CycleTimeSnapshotView,
    LeadTimeSnapshotView,
    LeadTimeForChangesSnapshotView
} from "@components/charts/valueDelivery/IssueMeasureSnapshotView";
import {OngoingIssuesTableView} from "@components/charts/ongoingIssues/OngoingIssuesTableView";
import {OngoingIssuesCountView} from "@components/charts/ongoingIssues/OngoingIssuesCountView";
import {OngoingDefectsSnapshotView} from "@components/charts/ongoingIssues/OngoingDefectsSnapshotView";
import {DefectsAgeSnapshotView} from "@components/charts/ongoingIssues/DefectsAgeSnapshotView";
import {PrAverageLifetimeSnapshotView} from "@components/charts/valueDelivery/PrMeasureSnapshotView";
import {PrLifetimeDynamicsChart} from "@components/charts/valueDelivery/PrMeasureDynamicsChart";
import {ReleaseFrequencySnapshotView} from "@components/charts/valueDelivery/ReleaseFrequencySnapshotView";
import {ReleaseFrequencyDynamicsChart} from "@components/charts/valueDelivery/ReleaseFrequencyDynamicsChart";


export function SummaryTabContent() {
    return <Grid className="gap-3 mt-3 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
        <Col numColSpan={1}>
            <Card className={"h-full"}>
                <LeadTimeSnapshotView />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="leadTime" />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <StatusTimeBreakdownSummaryChart />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <DefectsReportedFixedCumChart showLegend={false} />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card className={"h-full"}>
                <CompletedIssuesSnapshotView />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedCount" />
            </Card>
        </Col>
        <Col numColSpanMd={2}>
            <Card style={{height: "25rem"}}>
                <CumulativeFlowDiagramChart compact={true} />
            </Card>
        </Col>
    </Grid>
}

export function ValueDeliveryTabContent() {
    return <Grid numItems={1} className="gap-3 mt-3 xl:grid-cols-4">
        <Col>
            <Card className={"h-full"}>
                <LeadTimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="leadTime" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"leadTime"} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <CycleTimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="cycleTime" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"cycleTime"} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <PrAverageLifetimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <PrLifetimeDynamicsChart measure="prAverageLifetime" />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <LeadTimeForChangesSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="leadTimeForChanges" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"leadTimeForChanges"} />
            </Card>
        </Col>

        <Col>
            <Card className={"h-full"}>
                <ReleaseFrequencySnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <ReleaseFrequencyDynamicsChart measure="releaseFrequency" />
            </Card>
        </Col>

    </Grid>
}

export function TeamVelocityTabContent() {
    return <Grid className="gap-3 mt-3 grid-cols-1 xl:grid-cols-4">
        <Col>
            <Card className={"h-full"}>
                <CompletedIssuesSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedCount" />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <CompletedEstimateSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedEstimate" />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-4">
            <Card>
                <CompletedIssuesTableView className="w-full table-fixed" />
            </Card>
        </Col>
    </Grid>
}

export function OngoingIssuesTabContent() {
    return <Grid className="gap-3 mt-3 grid-cols-1 xl:grid-cols-3">
        <Col>
            <Card className={"h-full"}>
                <OngoingIssuesCountView />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <OngoingDefectsSnapshotView />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <DefectsAgeSnapshotView />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-3">
            <Card>
                <OngoingIssuesTableView pageSize={20} className="w-full table-fixed" />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-3">
            <Card>
                <OngoingIssuesTableView defects pageSize={20} className="w-full table-fixed" />
            </Card>
        </Col>
    </Grid>
}

export function SprintsTabContent() {
    return <Grid numItems={1} className="gap-3 mt-3">
        <Col>
            <Card className="h-[640px]">
                <SprintMeasurePlanFactChart measure="issuesCount" />
            </Card>
        </Col>
        <Col>
            <Card className="h-[640px]">
                <SprintMeasurePlanFactChart measure="estimate" />
            </Card>
        </Col>
    </Grid>
}

export function ExecutionFlowTabContent() {
    return <Grid numItems={1} className="gap-3 mt-3">
        {/*<Col numColSpan={1}>*/}
        {/*    <Card style={{height: "20rem"}}>*/}
        {/*        /!*<CompletedIssueStatusTimeSummaryChart />*!/*/}
        {/*        <StatusTimeBreakdownSummaryChart />*/}
        {/*    </Card>*/}
        {/*</Col>*/}
        {/*<Col numColSpan={1}>*/}
        {/*    <Card style={{height: "30rem"}}>*/}
        {/*        <StatusTimeBreakdownBarChart />*/}
        {/*    </Card>*/}
        {/*</Col>*/}
        <Col>
            <Card>
                <StatusBreakdownSummaryTable interval={'day'} />
            </Card>
        </Col>
        <Col>
            <Card style={{height: "50rem"}}>
                <CumulativeFlowDiagramChart />
            </Card>
        </Col>
    </Grid>
}

export function QualityTabContent() {
    return <Grid numItemsSm={1} numItemsLg={2} className="gap-3 mt-3">
        <Col numColSpan={1}>
            <Card style={{height: "30rem"}}>
                <DefectsReportedFixedChart />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "30rem"}}>
                <DefectsReportedFixedCumChart />
            </Card>
        </Col>
    </Grid>
}

export function ScopeForecastTabContent() {
    return <Grid className="gap-3 mt-3 sm:grid-cols-2 xl:grid-cols-4">
        <Col numColSpan={4}>
            <BurnDownChartWithOptions />
        </Col>
    </Grid>
}


