import {useDataSet} from "@utils/useDataSet";
import {FilterId, useFilters} from "@components/filters/FiltersContext";

const stageNamePattern = '%publish%'
const jobNamePattern = '%pages%'

export type ReleaseFreqSnapshotData = {
    jobCount: number,
}

export type ReleaseFreqDynamicsData = {
    items: ReleaseFreqDynamicsDataItem[]
}

export type ReleaseFreqDynamicsDataItem = {
    ts: number
    jobCount: number
}

export function useReleaseFreqSnapshotDataSet() {
    const filters = useFilters()

    const {data, ...queryResult} = useDataSet({
        dataset: 'pipeline_job',
        filters: {
            stageNamePattern: stageNamePattern,
            jobNamePattern: jobNamePattern,
            ...filters.getFilterSpec([FilterId.TimeRange])
        },
        select: [
            {name: 'job_count'},
        ]
    })

    if (data) {
        const snapshot: ReleaseFreqSnapshotData = {
            jobCount: data.data[0][0] as number,
        }

        return {data: snapshot, ...queryResult}
    }

    return {data: undefined, ...queryResult}
}

export function useReleaseFreqDynamicsDataSet() {
    const filters = useFilters()

    const {data, ...queryResult} = useDataSet({
        dataset: 'pipeline_job',
        filters: {
            ...filters.getFilterSpec([FilterId.TimeRange]),
            stageNamePattern: stageNamePattern,
            jobNamePattern: jobNamePattern,
        },
        select: [
            {name: 'finished_at_trunc', alias: 'ts', args: [{name: 'trunc_to', value: 'month'}]},
            {name: 'job_count'},
        ],
        orderBy: [
            {name: 'ts'},
        ],
    })

    if (data && data.data.length > 0) {
        const ds: ReleaseFreqDynamicsData = {
            items: data.data.map(x => ({
                ts: x[0] as number,
                jobCount: x[1] as number,
            } as ReleaseFreqDynamicsDataItem))
        }

        return {data: ds, isEmpty: false, ...queryResult}
    }

    return {data: undefined, isEmpty: data && data.data.length === 0, ...queryResult}
}