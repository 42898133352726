import {graphic} from 'echarts'
import {MarkLine1DDataItemOption} from "echarts/types/src/component/marker/MarkLineModel";
import {BadgeDelta, Flex, Title} from "@tremor/react";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import {useReleaseFreqDynamicsDataSet, useReleaseFreqSnapshotDataSet, ReleaseFreqSnapshotData, ReleaseFreqDynamicsData, ReleaseFreqDynamicsDataItem} from "./dataSources/cicdDataSources";
import {DoraBadge} from "@components/common/DoraBadge";

type ReleaseFrequencyMeasure = 'releaseFrequency'

export type ReleaseFrequencyDynamicsChartProps = {
    measure: ReleaseFrequencyMeasure,
}

const measureConfig = {
    releaseFrequency: {
        title: 'Release Frequency в динамике',
        seriesName: 'Кол-во релизов',
        seriesData: (data: ReleaseFreqDynamicsDataItem) => data.jobCount,
        isIncreasePositive: true,
        markLine: {
            value: (snapshot: ReleaseFreqSnapshotData, data: ReleaseFreqDynamicsData) =>
                data.items.length > 0 ? snapshot.jobCount / data.items.length : null,
            label: (snapshot: ReleaseFreqSnapshotData, data: ReleaseFreqDynamicsData) => {
                const value = data.items.length > 0 ? snapshot.jobCount / data.items.length : null
                return `Release Frequency: ${Number(value?.toFixed(1))}`
            },
            diffBadge: {
                isIncreasePositive: true,
                tooltip: 'Отклонение в последнем месяце',
            },
        }
    },
}

export function ReleaseFrequencyDynamicsChart({measure}: ReleaseFrequencyDynamicsChartProps) {
    const {data: snapshot, ...snapshotQueryResult} = useReleaseFreqSnapshotDataSet()
    const {data: response, ...queryResult} = useReleaseFreqDynamicsDataSet()
    const {seriesName, title, seriesData, markLine} = measureConfig[measure]

    if (!snapshot)
        return <NoChartData title={title} {...snapshotQueryResult} />

    if (!response)
        return <NoChartData title={title} {...queryResult} />

    const data = response.items.map(x => ([
        new Date(x.ts * 1000),
        seriesData(x)
    ]))

    const {baseValue, increase} = getIncrease();

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Flex justifyContent="between" alignItems="center">
                <Title className="my-0">{title}<DoraBadge /></Title>
                {increase && markLine.diffBadge && <BadgeDelta tooltip={markLine.diffBadge.tooltip}
                    deltaType={increase > 0 ? "increase" : "decrease"}
                    isIncreasePositive={markLine.diffBadge.isIncreasePositive}
                    size="xs">
                    {increase}%
                </BadgeDelta>}
            </Flex>
            <EChartsReact
                className="mt-3 h-full w-full"
                option={{
                    grid: {
                        left: 50,
                        right: 10,
                        top: 10,
                        bottom: 20
                    },
                    xAxis: {
                        type: 'time',
                    },
                    yAxis: {
                        type: 'value',
                    },
                    tooltip: {
                        trigger: 'axis',
                        valueFormatter: x => Number((x as number).toFixed(1)).toString()
                    },
                    series: [
                        {
                            name: seriesName,
                            type: 'line',
                            areaStyle: {
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(60, 130, 246, 0.5)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgb(60, 130, 246, 0)'
                                    }])
                            },
                            smooth: true,
                            data: data,
                            markLine: baseValue !== undefined
                                ? {
                                    symbol: [],
                                    animation: false,
                                    silent: true,
                                    data: [
                                        {
                                            yAxis: baseValue,
                                            label: {
                                                formatter: markLine!.label(snapshot, response),
                                                position: 'insideStartTop'
                                            }
                                        } as MarkLine1DDataItemOption,
                                    ]
                                } : undefined
                        },
                    ],
                }}
            />
        </Flex>
    );

    function getIncrease() {
        if (!snapshot || !response)
            return {increase: undefined, basValue: undefined}

        const baseValue = markLine.value(snapshot, response)
        if (baseValue === null)
            return {increase: undefined, basValue: undefined}

        const last = seriesData(response.items[response.items.length - 1])

        return {
            baseValue: baseValue,
            increase: Math.round(1000 * (last - baseValue) / baseValue) / 10
        }
    }
}
