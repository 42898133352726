import {PropsWithChildren, useEffect, useState} from "react";

type Props = PropsWithChildren<{
    waitBeforeShow?: number
}>

export function Delayed({ children, waitBeforeShow = 100 }: Props) {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true)
        }, waitBeforeShow)
        return () => clearTimeout(timer)
    }, [waitBeforeShow])

    return <>
        {isShown ? children : null}
    </>
}