import {Title} from "@tremor/react";
import {from} from "linq-to-typescript";
import {getDimensionColor} from "@utils/colorPaletteUtils";
import {SprintIssueDto} from "@services/sprintApi";
import {LinearBreakUpSection, LinearBreakUpView} from "@components/common/LinearBreakUpView";

export type SprintWorkBreakupViewProps = {
    issues: SprintIssueDto[]
    byIssues: boolean
}

export function SprintWorkBreakupView({issues, byIssues}: SprintWorkBreakupViewProps) {
    const sections = from(issues)
        .where(x => byIssues || x.estimate > 0)
        .groupBy(x => x.issueTypeId)
        .orderBy(x => x.key)
        .select(g => ({
            name: g.first().issueTypeName,
            value: byIssues ? g.count() : g.sum(i => i.estimate),
            color: getDimensionColor('issue_status_id', g.key),
        } as LinearBreakUpSection))
        .toArray()

    return <div>
        <Title className={"mb-3 mt-5"}>Задачи по типам</Title>
        <LinearBreakUpView sections={sections} />
    </div>
}

