import {Card, Col, Grid, Title} from "@tremor/react";
import ProjectsRadarChart from "@components/charts/radars/ProjectsRadarChart";

export function TeamsCompareTab() {
    return <Grid className="gap-3 mt-3 sm:grid-cols-2 xl:grid-cols-4">
        <Col numColSpan={4} style={{height: "40rem"}}>
            <ProjectsRadarChart />
        </Col>
        <Col numColSpan={4}>
            <Title>Сводка по целям</Title>
        </Col>
        <GoalsList />
    </Grid>

}

function GoalsList() {
    const goals: Goal[] = [
        {
            name: "Подключить три новые страны в приложении",
            metric: "Количество завершенных задач из списка",
            minValue: 0,
            currentValue: 3,
            targetValue: 3,
            lowerIsBetter: false,
            status: "reached",
            dueIn: 72
        },
        {
            name: "Lead Time за последний квартал не превышает 20 д.",
            metric: "Lead Time",
            currentValue: 25,
            minValue: 40,
            targetValue: 20,
            lowerIsBetter: true,
            status: "failed",
            dueIn: -2,
        },
        {
            name: "Увеличить покрытие Unit тестами до 70% по проекту DSA",
            metric: "% покрытия",
            currentValue: 55,
            minValue: 0,
            targetValue: 70,
            lowerIsBetter: false,
            status: "in_progress",
            dueIn: 205,
        },
        {
            name: "Сократить время загрузки приложения до 2 секунд PCABO3",
            metric: "Время загрузки, сек.",
            currentValue: 5,
            minValue: 6,
            targetValue: 2,
            lowerIsBetter: true,
            status: "in_progress",
            dueIn: 53,
        },
        {
            name: "Запустить новую маркетинговую кампанию ATOC",
            metric: "Маркетинговая компания запущена",
            currentValue: 1,
            minValue: 0,
            targetValue: 1,
            lowerIsBetter: false,
            status: "reached",
            dueIn: 22,
        }
    ]

    return <>
        {goals.map((g, i) =>
            <Col key={`goal${i}`}>
                <Card className={`bg-white rounded-lg shadow-lg border-l-4 ${getBorderColor(g)}`}>
                    <h2 className="text-xl font-semibold">{g.name}</h2>
                    <ul className="mt-2 text-gray-600">
                        <li className="tooltip rel">Текущее значение: <span className="font-bold">{g.currentValue} / {g.targetValue}</span>
                            <span className="tooltiptext">{g.metric}</span>
                        </li>
                        {g.dueIn >= 0
                            ? <li>Осталось дней: <span className="font-bold">{g.dueIn}</span></li>
                            : <li>Просрочено дней: <span className="font-bold text-red-400">{-g.dueIn}</span></li>}

                    </ul>
                    <div className="relative pt-1 mt-4 tooltip">
                        <div className={`overflow-hidden h-4 mb-4 text-xs flex rounded ${getBgColor(g, true)}`}>
                            <div style={{width: `${getPercentage(g)}%`}} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getBgColor(g)}`}></div>
                        </div>
                        <span className="tooltiptext">{getStatusDescription(g)}</span>
                    </div>
                </Card>
            </Col>
        )}
    </>

    function getStatusDescription({status}: Goal) {
        switch (status) {
            case "reached": return "Цель достигнута"
            case "failed": return "Цель не достигнута"
            case "in_progress": return "Цель вероятно не будет достигнута"
            default: return ""
        }
    }

    function getPercentage({currentValue, minValue, targetValue}: Goal) {
        return Math.round((currentValue - minValue) / (targetValue - minValue) * 100)
    }

    function getBorderColor({status}: Goal) {
        switch (status) {
            case "reached":
                return "border-green-500"
            case "failed":
                return "border-red-500"
            default:
                return "border-yellow-500"
        }
    }

    function getBgColor(goal: Goal, light: boolean = false) {
        switch (goal.status + (light ? "_light" : "")) {
            case "reached":
                return "bg-green-500"
            case "failed":
                return "bg-red-500"
            case "in_progress":
                return "bg-yellow-500"
            case "reached_light":
                return "bg-green-200"
            case "failed_light":
                return "bg-red-200"
            case "in_progress_light":
                return "bg-yellow-200"
            default:
                return ""
        }
    }

}

type Goal =
    {
        name: string
        metric: string
        currentValue: number
        minValue: number,
        targetValue: number
        lowerIsBetter: boolean
        status: "reached" | "failed" | "in_progress"
        dueIn: number
    }