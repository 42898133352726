import {CSSProperties} from "react";
import {from} from "linq-to-typescript";

export type LinearBreakUpProps = {
    sections: LinearBreakUpSection[]
}

export function LinearBreakUpView({sections}: LinearBreakUpProps) {
    if (!sections.length) {
        return <span>-</span>
    }

    const textStyle: CSSProperties = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        //textOverflow: "ellipsis",
        marginRight: 0,
    }

    const progressBarStyle: CSSProperties = {
        borderStyle: "solid",
        borderWidth: "thick",
        marginLeft: 0,
        opacity: 1,
        width: "100%",
        marginTop: 0,
        marginBottom: "6px",
        borderRadius: "3px",
    }

    const total = from(sections).sum(x => x.value)

    return <div className={"flex justify-start"}>
        {sections.map(((x, i) => (
            <div key={i} className={"flex flex-col"} style={{width: `${getPercentage(x.value)}%`, marginRight: i < sections.length-1 ? "2px" : 0}}>
                <div title={`${getPercentage(x.value)}% (${x.value}/${total})`} style={textStyle}>
                    <span>
                        <h3 style={{display: 'inline', fontSize: "11px"}}>{getPercentage(x.value)}%</h3>
                        <span style={{fontSize: "10px", opacity:0.6}}> ({x.value}/{total})</span>
                    </span>
                </div>
                <hr style={{borderColor: x.color, ...progressBarStyle}}/>
                <div title={x.name} style={{fontSize: "10px", ...textStyle, whiteSpace: 'normal', wordWrap: 'break-word'}}>{x.name}</div>
            </div>
        )))}
    </div>

    function getPercentage(val: number) {
        return Number((100 * val / total).toFixed(0))
    }
}

export type LinearBreakUpSection = {
    name: string
    value: number
    color: string
}