import {Card, Col, Grid} from "@tremor/react";
import {OngoingIssuesCountView} from "@components/charts/ongoingIssues/OngoingIssuesCountView";
import {OngoingDefectsSnapshotView} from "@components/charts/ongoingIssues/OngoingDefectsSnapshotView";
import {DefectsAgeSnapshotView} from "@components/charts/ongoingIssues/DefectsAgeSnapshotView";
import {OngoingIssuesTableView} from "@components/charts/ongoingIssues/OngoingIssuesTableView";

export function TeamOpenIssuesTab() {
    return <Grid className="gap-3 mt-3 grid-cols-1 xl:grid-cols-3">
        <Col>
            <Card className={"h-full"}>
                <OngoingIssuesCountView />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <OngoingDefectsSnapshotView />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <DefectsAgeSnapshotView />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-3">
            <Card>
                <OngoingIssuesTableView pageSize={20} className="w-full table-fixed" />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-3">
            <Card>
                <OngoingIssuesTableView defects pageSize={20} className="w-full table-fixed" />
            </Card>
        </Col>
    </Grid>
}