import {Badge, Callout, Card, Col, Grid, Select, SelectItem, Subtitle, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Title} from "@tremor/react";
import {SprintWorkProgressView} from "@components/charts/sprints/SprintWorkProgressView";
import {useFilters} from "@components/filters/FiltersContext";
import {useQuery} from "react-query";
import {sprintApi, SprintIssueDto} from "@services/sprintApi";
import {SprintWorkBreakupView} from "@components/charts/sprints/SprintWorkBreakupView";
import NoChartData from "@components/common/NoChartData";
import {SprintVelocityChart} from "@components/charts/sprints/SprintVelocityChart";
import {formatInstantAsDate} from "@utils/dateUtils";
import {useState} from "react";
import {from} from "linq-to-typescript";

export function SprintTab() {
    const {sprintId, isLoaded: filtersLoaded} = useFilters()

    if (!filtersLoaded) {
        return <NoChartData title={null} isLoading={true} />
    }

    return sprintId
        ? <SprintView sprintId={sprintId}/>
        : <Callout className={"m-5"} title={"Не выбран спринт"} color={"yellow"}>
            Выберите спринт для отображения данных.
        </Callout>
}

export function SprintView({sprintId}: {sprintId: string}) {
    const {data: sprint, ...sprintQueryResult} = useQuery(['sprint', sprintId], () => sprintApi.getSprint(sprintId))
    const {data: issues, ...issuesQueryResult} = useQuery(['sprint-issues', sprintId], () => sprintApi.getIssues(sprintId))
    const [byIssues, setByIssues] = useState(true)

    if (!issues) {
        return <NoChartData title={"Статистика по спринту"} {...issuesQueryResult} />
    }

    if (!sprint) {
        return <NoChartData title={"Статистика по спринту"} {...sprintQueryResult} />
    }

    return <Grid numItemsSm={1} numItemsLg={2} className="gap-3 mt-3">
        <Col numColSpanSm={1} numColSpanLg={2}>
            <div className={'flex flex-row justify-between items-center'}>
                <div className={'flex flex-row items-baseline'}>
                    <Title>{sprint.sprintName}</Title>
                    <Subtitle className={"ml-2"}>({formatInstantAsDate(sprint.from)} &ndash; {formatInstantAsDate(sprint.to)})</Subtitle>
                </div>
                <div className={'flex flex-row items-center'}>
                     <label htmlFor={'byIssues'}>Расчет:</label>
                    <Select className={'ml-2 min-w-[9rem]'} id={'byIssues'} value={byIssues ? 'byIssues' : 'bySp'} enableClear={false} onValueChange={x => setByIssues(x === 'byIssues')}>
                        <SelectItem value={'byIssues'}>По задачам</SelectItem>
                        <SelectItem value={'bySp'}>По SP</SelectItem>
                    </Select>
                </div>
            </div>
        </Col>
        <Col numColSpan={1}>
            <Card style={{minHeight: "250px"}}>
                <SprintWorkProgressView sprint={sprint} issues={issues} byIssues={byIssues}/>
                <SprintWorkBreakupView issues={issues} byIssues={byIssues}/>
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card className={"h-full"} style={{minHeight: "250px"}}>
                {sprint.sprintGroupId
                    ? <SprintVelocityChart sprintGroupId={sprint.sprintGroupId} sprint={sprint} byIssues={byIssues}/>
                    : <NoChartData title={"Скорость (Velocity)"} message={"Спринт не относится к какой-либо серии спринтов"} />}
            </Card>
        </Col>
        <Col numColSpanSm={1} numColSpanLg={2}>
            <Card>
                <Title>Задачи спринта</Title>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Задача</TableHeaderCell>
                            <TableHeaderCell className={'text-center'}>SP</TableHeaderCell>
                            <TableHeaderCell className={'text-center'}>Статус</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {from(issues).orderBy(x => getIssueStatusOrder(x)).select(x => <TableRow key={x.issueId}>
                            <TableCell className={'whitespace-normal'}>
                                <div>
                                    <b className={"cursor-pointer"}
                                       onClick={x.issueUrl ? (() => window.open(x.issueUrl!, '_blank')) : undefined}>
                                        {x.issueKey}
                                    </b>
                                    <span className={'text-xs'}> [{x.issueTypeName}]</span>
                                </div>
                                <div className={'text-xs'}>{x.summary}</div>
                            </TableCell>
                            <TableCell className={'text-center'}>
                                {x.estimate ? x.estimate : '-'}
                            </TableCell>
                            <TableCell className={'text-center'}>
                                <Badge color={getIssueStatusColor(x)}>{getIssueStatusName(x)}</Badge>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </Card>
        </Col>
    </Grid>

    function getIssueStatusName(issue: SprintIssueDto) {
        return sprint?.closedAt
            ? issue.issueCompleted
                ? 'Завершено'
                : 'Перенесено'
            : issue.issueStatusName
    }

    function getIssueStatusOrder(issue: SprintIssueDto) {
        return sprint?.closedAt
            ? issue.issueCompleted
                ? 1
                : 0
            : issue.issueStatusSortOrder + 100000
    }

    function getIssueStatusColor(issue: SprintIssueDto) {
        return issue.issueCompleted
            ? 'green'
            : sprint?.closedAt
                ? 'red'
                : 'yellow'
    }
}