import {api} from "./api";
import qs from "qs";

export type MemberDto = {
    id: string
    fullName: string
    active: boolean
    teams: MemberTeamDto[]
}

export type MemberTeamDto = {
    id: string
    name: string
    active: boolean
}

export type MembersRequest = {
    teams?: string[]
    activeOnly?: boolean
}

export class MemberApi {
    public async getMembers(request?: MembersRequest) {
        return await api.get<MemberDto[]>(`members`, qs.stringify(request ?? {}, {allowDots: true}))
    }
}

export const memberApi = new MemberApi()
