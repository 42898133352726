import React from 'react';
import {useQuery} from "react-query";
import {Flex, Title} from "@tremor/react";
import {renderToStaticMarkup} from "react-dom/server";
import {FilterSpecDto} from "@services/sharedApiModels";
import {issuesDataSetApi} from "@services/issuesDataSetApi";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import {daysToDurationString} from "@components/common/DurationString";
import {FilterId, useFilters} from "@components/filters/FiltersContext";
import {DoraBadge} from '@components/common/DoraBadge';
import {IssueMeasureSnapshotData, useIssueMeasureSnapshotDataSet} from "@components/charts/valueDelivery/dataSources/issueDataSources";

type LeadTimeMeasure = 'leadTime' | 'cycleTime' | 'leadTimeForChanges'

export type LeadTimeDistributionChartProps = {
    measure?: LeadTimeMeasure
}

const measureConfig = {
    leadTime: {
        title: 'Распределение Lead time',
        queryKey: 'getLeadTimeDist',
        queryFn: (filters: FilterSpecDto) => issuesDataSetApi.getLeadTimeDist({
            percentile: 85,
            filters,
        }),
        getPercentileVal: (snapshot: IssueMeasureSnapshotData) => snapshot.leadTimeDays,
        tooltipValueLabel: 'lead time',
    },
    cycleTime: {
        title: 'Распределение Cycle time',
        queryKey: 'getCycleTimeDist',
        queryFn: (filters: FilterSpecDto) => issuesDataSetApi.getCycleTimeDist({
            percentile: 85,
            filters,
        }),
        getPercentileVal: (snapshot: IssueMeasureSnapshotData) => snapshot.cycleTimeDays,
        tooltipValueLabel: 'cycle time',
    },
    leadTimeForChanges: {
        title: 'Распределение Lead time for changes',
        queryKey: 'getLeadTimeForChangesDist',
        queryFn: (filters: FilterSpecDto) => issuesDataSetApi.getLeadTimeForChangesDist({
            percentile: 85,
            filters,
        }),
        getPercentileVal: (snapshot: IssueMeasureSnapshotData) => snapshot.leadTimeForChangesDays,
        tooltipValueLabel: 'lead time for changes',
    },
}

export default function LeadTimeDistributionChart({measure = "leadTime"}: LeadTimeDistributionChartProps) {
    const {queryKey, queryFn, title, getPercentileVal, tooltipValueLabel} = measureConfig[measure]

    const filters = useFilters()
    const filterSpec = filters.getFilterSpec([FilterId.TimeRange, FilterId.Projects, FilterId.IssueTypes, FilterId.Teams])

    const {data: snapshot} = useIssueMeasureSnapshotDataSet()
    const {data: response, ...queryResult} = useQuery([queryKey, filterSpec], {
        queryFn: () => filters.isLoaded
            ? queryFn(filterSpec)
            : Promise.resolve(undefined),
    })

    if (!filters.isLoaded)
        return <NoChartData title={title} isLoading={true} />

    if (!response || !response.items.length)
        return <NoChartData title={title} {...queryResult} isEmpty={response && !response.items.length} />

    let percentile: number | undefined = undefined
    let percentileVal = undefined
    if (snapshot && snapshot.percentile > 0) {
        percentile = snapshot.percentile
        percentileVal = getPercentileVal(snapshot)
    }

    const {step, items: data} = response

    const minX = data[0].from
    const maxX = data[data.length - 1].from + step

    for (let x = minX; x <= maxX - step; x += step) {
        if (!data.some(d => Math.abs(d.from - x) < 0.0000001)) {
            data.push({from: x, qty: 0})
        }
    }

    data.sort((a, b) => a.from - b.from)

    const enableDataZoom = true

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Title className="mb-3">{title}{measure === "leadTimeForChanges" ? <DoraBadge /> : null}</Title>
            <EChartsReact
                className="h-full w-full"
                option={{
                    grid: {
                        left: 50,
                        right: 10,
                        top: 20,
                        bottom: enableDataZoom ? 75 : 20
                    },
                    xAxis: {
                        type: 'value',
                        interval: enableDataZoom ? undefined : step,
                        min: minX,
                        max: maxX
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Частота (кол-во задач)',
                        nameLocation: 'middle',
                        nameGap: 40,
                    },
                    series: [
                        {
                            type: 'bar',
                            barCategoryGap: 1,
                            data: data.map(x => [x.from + step / 2, x.qty]),
                            clip: false,
                            markLine: percentileVal ? {
                                symbol: [],
                                animation: false,
                                silent: true,
                                data: [
                                    {
                                        xAxis: percentileVal,
                                        label: {
                                            formatter: x => {
                                                const val = x.value as number
                                                return `${percentile}% (${daysToDurationString(val)})`
                                            },
                                            position: "insideEndTop"
                                        }
                                    }
                                ],
                            } : undefined,
                        },
                    ],
                    tooltip: {
                        formatter: (x: any) => {
                            const from = Math.round(((x[0].axisValue as number) - (step / 2)) * 10000000) / 10000000
                            const qty = data.find(x => x.from === from)?.qty || 0
                            const to = Math.round((from + step) * 10000000) / 10000000

                            const total = data.map(x => x.qty).reduce((s, x) => s + x, 0)
                            const cumQty = data.filter(x => x.from <= from).map(x => x.qty).reduce((s, x) => s + x, 0)
                            const percentile = Math.floor((cumQty / total) * 100);

                            return renderToStaticMarkup(<>
                                Кол-во задач<br />
                                с {tooltipValueLabel} от {from} до {to} дн.: <b>{qty}</b><br />
                                с {tooltipValueLabel} до {to} дн.: <b>{cumQty}</b> ({percentile}%)
                            </>)
                        },
                        trigger: 'axis',
                        renderMode: 'html',
                    },
                    animation: false,
                    dataZoom: enableDataZoom ? {
                        show: true,
                        type: 'slider',
                    } : undefined
                }}
            />
        </Flex>
    )
}
