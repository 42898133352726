import {from} from "linq-to-typescript";
import {Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow} from "@tremor/react";
import {ReactElement, TableHTMLAttributes, useEffect, useState} from "react";
import moment, {duration} from "moment";
import {useDataSet} from "@utils/useDataSet";
import NoChartData from "@components/common/NoChartData";
import {hoursToDurationString} from "@components/common/DurationString";
import {FixedInterval, translateIntervalNameToString} from "@components/common/dateTimeStringUtils";
import {DataValue, DataValueType, QueryRequest} from "@services/dataSetApi";

export type TableViewProps = {
    query: QueryRequest
    columns?: Array<TableColumnConfig>
    pageSize?: number
} & TableHTMLAttributes<HTMLTableElement>

export type DataValueFormatter = (val: DataValue, row: DataValue[]) => ReactElement | string

export type TableColumnConfig = {
    name: string
    displayName?: string
    formatter?: DataValueFormatter
    nullFormatter?: DataValueFormatter
}

export const defaultFormatters: {[type in DataValueType]: DataValueFormatter} = {
    String: (val) => val as string,
    Bool: (val) => val ? 'Да' : 'Нет',
    Double: (val) => (Math.round((val as number) * 100) / 100).toString(),
    Integer: (val) => (val as number).toString(),
    Instant: (val) => moment((val as number) * 1000).format('DD.MM.YY'),
    Period: (val) => hoursToDurationString(duration(val as string).asHours()),
    TruncateField: (val) => translateIntervalNameToString(val as FixedInterval, 1)
}

const defaultNullFormatter: DataValueFormatter = () => '-'

export function TableView({query, columns, pageSize, ...tableProps}: TableViewProps) {
    const title = "Таблица"
    //const filters = useFilters()
    const [page, setPage] = useState(1)
    useEffect(() => setPage(1), [query]);
    const {data, ...queryResult} = useDataSet({
        ...query,
        limit: pageSize,
        offset: pageSize ? (page - 1) * pageSize : undefined
    })
    const {data: countData, ...countQueryResult} = useDataSet({
        ...query,
        requestCountOnly: true
    })

    if (!data || !data.data.length) {
        return <NoChartData title={title} {...queryResult} isEmpty={data && !data.data.length} />
    }

    if (!countData) {
        return <NoChartData title={title} {...countQueryResult} />
    }

    if (!columns) {
        columns = data.columns.map(x => ({
            name: x.name
        }))
    }

    const columnsMeta = from(data.columns).toObject(x => x.name)

    const missingColumn = columns.find(x => !columnsMeta[x.name])
    if (missingColumn) {
        return <NoChartData title={title} message={`Поле '${missingColumn.name}' не найдено`} />
    }

    for (const cfg of columns) {
        cfg.displayName = cfg.displayName || columnsMeta[cfg.name].displayName
    }

    const paginationItems = pageSize ? [page] : undefined
    if (paginationItems && pageSize) {
        if (page > 1) {
            paginationItems.unshift(page - 1)
            if (page > 4) {
                paginationItems.unshift(0)
                paginationItems.unshift(1)
            } else if (page > 2) {
                for (let i = page - 2; i >= 1; i--) {
                    paginationItems.unshift(i)
                }
            }
        }

        const totalCount = countData.data[0][0] as number
        const lastPage = Math.ceil(totalCount / pageSize)
        if (page < lastPage) {
            paginationItems.push(page + 1)
            if (lastPage - page > 3) {
                paginationItems.push(0)
                paginationItems.push(lastPage)
            } else if (lastPage - page > 1) {
                for (let i = page + 2; i <= lastPage; i++) {
                    paginationItems.push(i)
                }
            }
        }
    }

    return <>
        {(paginationItems && paginationItems.length > 1) && <>
            <p style={{textAlign: 'right'}}>
                {paginationItems.map((x, i) => x === 0
                    ? '...'
                    : <span key={i} className={`ml-1 mr-1 cursor-pointer ${x === page ? 'font-bold' : ''}`} onClick={() => setPage(x)}>{x}</span>)}
            </p>
        </>}

        <Table {...tableProps}>
            <TableHead>
                <TableRow>
                    {columns.map(x => <TableHeaderCell
                        key={x.name}
                    >
                        {x.displayName}
                    </TableHeaderCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.data.map((row, rowIndex) => <TableRow key={rowIndex}>
                    {columns!.map(x => <TableCell
                        style={{whiteSpace: 'break-spaces'}}
                        key={x.name}
                    >
                        {formatValue(x, data.data[rowIndex], columnsMeta[x.name].index)}
                    </TableCell>)}
                </TableRow>
                )}
            </TableBody>
        </Table>
    </>

    function formatValue(col: TableColumnConfig, row: DataValue[], colIndex: number) {
        const meta = columnsMeta[col.name]
        const val = row[meta.index]
        const formatter = val === null ? (col.nullFormatter || defaultNullFormatter) : (col.formatter || defaultFormatters[meta.type])
        return formatter(val, row)
    }
}