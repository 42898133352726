import { Bold, Metric, Text, Title } from "@tremor/react";
import NoChartData from "@components/common/NoChartData";
import { FiltersOverride, useCommitCountDynamicsDataSet, useCommitCountSnapshotDataSet } from "./dataSources/commitDataSources";
import { getWordInCase } from "@components/common/dateTimeStringUtils";

type CommitCountSnapshotViewProps = {
    filtersOverride?: FiltersOverride;
};

export function CommitCountSnapshotView({ filtersOverride }: CommitCountSnapshotViewProps) {
    const title = "Количество коммитов";
    const { data, ...queryResult } = useCommitCountSnapshotDataSet(filtersOverride);
    const { data: dynamicsData, ...dynamicsQueryResult } = useCommitCountDynamicsDataSet(filtersOverride);

    if (!data || !data.totalCommits)
        return <NoChartData title={title} isEmpty={data && !data.totalCommits} {...queryResult} />;

    if (!dynamicsData || dynamicsQueryResult.isEmpty)
        return <NoChartData title={title} {...dynamicsQueryResult} />;

    const weeksCount = dynamicsData.items.length;
    const commitsPerWeek = Number((data.totalCommits/weeksCount).toFixed(1));

    return (
        <>
            <Title>{title}</Title>
            <Metric className="mt-1">{data.totalCommits}</Metric>
            <Text>
                Всего коммитов за выбранный период. В среднем <Bold>{commitsPerWeek}</Bold> {getWordInCase('коммит', commitsPerWeek, 'nominative')} в неделю.
            </Text>
        </>
    );
}