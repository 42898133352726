import {Card, Col, Grid} from "@tremor/react";
import {CompletedEstimateSnapshotView, CompletedIssuesSnapshotView} from "@components/charts/valueDelivery/IssueMeasureSnapshotView";
import {LeadTimeDynamicsChart} from "@components/charts/valueDelivery/IssueMeasureDynamicsChart";
import {CompletedIssuesTableView} from "@components/charts/valueDelivery/CompletedIssuesTableView";

export function TeamPerformanceTab() {
    return <Grid className="gap-3 mt-3 grid-cols-1 xl:grid-cols-4">
        <Col>
            <Card className={"h-full"}>
                <CompletedIssuesSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedCount" />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <CompletedEstimateSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="completedEstimate" />
            </Card>
        </Col>
        <Col className="w-full xl:col-span-4">
            <Card>
                <CompletedIssuesTableView className="w-full table-fixed" />
            </Card>
        </Col>
    </Grid>
}