import moment from "moment/moment";
import {DataValue} from "@services/dataSetApi";

export const addIntervalToDate = (date: Date, interval: 'day' | 'week' | 'month' | 'year', count: number = 1) => {
    switch (interval)
    {
        case "day": return date.setDate(date.getDate() + count);
        case "week": return date.setDate(date.getDate() + count * 7);
        case "month": return date.setMonth(date.getMonth() + count);
        case "year": return date.setFullYear(date.getFullYear() + count);
    }
}

export function formatInstantAsDate(instant: DataValue, format: string = 'DD.MM.YY') {
    if (!instant) {
        return ''
    }
    return moment((instant as number) * 1000).format(format)
}