import {Badge, BadgeProps, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow} from "@tremor/react";
import {teamApi, TeamDto, TeamMemberDto} from "@services/teamApi";
import {useQuery} from "react-query";
import NoChartData from "@components/common/NoChartData";
import {getWordInCase} from "@components/common/dateTimeStringUtils";
import {useDataSet} from "@utils/useDataSet";
import {FilterId, useEditableFilters, useFilters} from "@components/filters/FiltersContext";
import {from} from "linq-to-typescript";
import {useTenantNavigate} from "@services/tenants";
import {IssueMeasureSnapshotData} from "@components/charts/valueDelivery/dataSources/issueDataSources";

enum Rating4 {
    NeedsImprovement = 0,
    Fair = 1,
    Good = 2,
    Elite = 3
}

function getLeadTimeRating(leadTimeDays: number): Rating4 {
    if (leadTimeDays > 40)
        return Rating4.NeedsImprovement
    else if (leadTimeDays > 30)
        return Rating4.Fair
    else if (leadTimeDays > 20)
        return Rating4.Good
    else
        return Rating4.Elite
}

function getCycleTimeRating(leadTimeDays: number): Rating4 {
    if (leadTimeDays > 20)
        return Rating4.NeedsImprovement
    else if (leadTimeDays > 10)
        return Rating4.Fair
    else if (leadTimeDays > 7)
        return Rating4.Good
    else
        return Rating4.Elite
}

function getAvgIssuesPerMonthRating(avgIssuesPerMonth: number): Rating4 {
    if (avgIssuesPerMonth >= 20)
        return Rating4.Elite
    else if (avgIssuesPerMonth >= 12)
        return Rating4.Good
    else if (avgIssuesPerMonth >= 5)
        return Rating4.Fair
    else
        return Rating4.NeedsImprovement
}

function getAvgSpPerMonthRating(avgSpPerMonth: number): Rating4 {
    if (avgSpPerMonth >= 80)
        return Rating4.Elite
    else if (avgSpPerMonth >= 40)
        return Rating4.Good
    else if (avgSpPerMonth >= 20)
        return Rating4.Fair
    else
        return Rating4.NeedsImprovement
}


function getVelocityRating(stat: IssueMeasureSnapshotData): Rating4 {
    return getAvgRating([
        getLeadTimeRating(stat.leadTimeDays),
        getCycleTimeRating(stat.cycleTimeDays)
    ])
}

function getAvgRating(values: Rating4[]): Rating4 {
    return Math.round(from(values).sum(x => x as number) / values.length) as Rating4
}

function getPerformanceRating(avgIssuesPerMonth: number, avgSpPerMonth: number) {
    return getAvgRating([getAvgIssuesPerMonthRating(avgIssuesPerMonth), getAvgSpPerMonthRating(avgSpPerMonth)])
}

export function TeamsListTab() {
    const {data: teams, ...queryResult} = useQuery('teams', {
        queryFn: () => teamApi.getTeams()
    })

    const filters = useEditableFilters()

    const {data: issueMeasures, ...issueMeasuresQueryResult} = useAllTeamsIssueMeasureSnapshots()
    const {data: perfVelocity, ...perfVelocityQueryResult} = useAllTeamsPerformanceVelocity()

    const navigate = useTenantNavigate()

    if (!teams)
        return <NoChartData title={"Команды"} {...queryResult} />

    if (!issueMeasures)
        return <NoChartData title={"Команды"} {...issueMeasuresQueryResult} />

    if (!perfVelocity)
        return <NoChartData title={"Команды"} {...perfVelocityQueryResult} />

    return <Table>
        <TableHead>
            <TableRow>
                <TableHeaderCell>Команда</TableHeaderCell>
                <TableHeaderCell>Состав</TableHeaderCell>
                <TableHeaderCell style={{textAlign: 'center'}}>Скорость</TableHeaderCell>
                <TableHeaderCell style={{textAlign: 'center'}}>Результативность</TableHeaderCell>
                <TableHeaderCell style={{textAlign: 'center'}}>Завершено задач</TableHeaderCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {teams.map((team) =>
                <TableRow key={team.id}>
                    <TableCell style={{whiteSpace: 'break-spaces'}}>
                        <span style={{cursor: 'pointer'}} onClick={() => navigateToTeam(team, 'velocity')}><b>{team.name}</b></span>
                    </TableCell>
                    <TableCell style={{whiteSpace: 'break-spaces'}}>
                        <TeamMembers members={team.members} teamId={team.id} teamName={team.name} />
                    </TableCell>
                    <TableCell style={{textAlign: 'center'}}>
                        {issueMeasures[team.id]
                            ? <Rating4Badge
                                style={{cursor: 'pointer'}}
                                value={getVelocityRating(issueMeasures[team.id])}
                                onClick={() => navigateToTeam(team, 'velocity')}
                            />
                            : '-'}
                    </TableCell>
                    <TableCell style={{textAlign: 'center'}}>
                        {perfVelocity[team.id]
                            ? <Rating4Badge
                                style={{cursor: 'pointer'}}
                                value={getPerformanceRating(perfVelocity[team.id].issueVelocity ?? 0, perfVelocity[team.id].spVelocity ?? 0)}
                                onClick={() => navigateToTeam(team, 'performance')}
                            />
                            : '-'}
                    </TableCell>
                    <TableCell style={{textAlign: 'center'}}>
                        {issueMeasures[team.id]?.completedCount || 0}
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    </Table>

    function navigateToTeam(team: TeamDto, tab: string) {
        filters.setTeams([{value: team.id, name: team.name}])
        navigate(`dashboard/teams/${tab}`)
    }

    function TeamMembers({members, teamId, teamName}: {members: TeamMemberDto[], teamId: string, teamName: string}) {
        const max = 5
        const cutOff = members.length > max + 1
        const navigate = useTenantNavigate()
        const filters = useEditableFilters()

        const handleShowAllMembers = () => {
            filters.setTeams([{value: teamId, name: teamName}])
            navigate(`dashboard/members`)
        }

        const handleMemberClick = (memberId: string) => {
            filters.setMemberId(memberId)
            navigate(`dashboard/members/stats`)
        }

        return <div className="text-xs" title={cutOff ? `Всего ${members.length} ${getWordInCase('участник', members.length, 'nominative')}` : undefined}>
            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                {(cutOff ? members.slice(0, max) : members)
                    .map(m => (
                        <li key={m.id} style={{marginBottom: '4px'}}>
                            <span 
                                style={{cursor: 'pointer'}}
                                onClick={() => handleMemberClick(m.id)}
                            >
                                <b>{m.name}</b>
                            </span>
                        </li>
                    ))}
            </ul>
            {cutOff && (
                <div 
                    style={{marginTop: 4, cursor: 'pointer'}}
                    onClick={handleShowAllMembers}
                >
                    <i>+{members.length - max} чел.</i>
                </div>
            )}
        </div>
    }

    function Rating4Badge({value, ...props}: {value: Rating4} & Omit<BadgeProps, "color">) {
        const [word, color] = getWordAndColor()
        return <>
            <Badge color={color} {...props}>{word}</Badge>
        </>

        function getWordAndColor() {
            switch (value) {
                case Rating4.Elite: return ['Супер!', 'green']
                case Rating4.Good: return ['Хорошо', 'blue']
                case Rating4.Fair: return ['Неплохо', 'yellow']
                case Rating4.NeedsImprovement: return ['Требуется внимание', 'red']
            }
        }
    }

    function useAllTeamsIssueMeasureSnapshots() {
        const percentile = 85

        const filters = useFilters()

        const {data, ...queryResult} = useDataSet({
            dataset: 'issue',
            filters: {
                ...filters.getFilterSpec([FilterId.TimeRange, FilterId.IssueTypes]),
                completedOnly: true
            },
            select: [
                {name: 'team_id'},
                {name: 'issues_count'},
                {name: 'completed_issues_count'},
                {name: 'total_estimate'},
                {name: 'completed_estimate'},
                {name: 'issue_lead_time_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
                {name: 'issue_cycle_time_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
                {name: 'issue_lead_time_for_changes_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
            ]
        })

        if (data) {
            const snapshots = Object.fromEntries(data.data.map(x => [x[0] as string, {
                issueCount: x[1] as number,
                completedCount: x[2] as number,
                totalEstimate: x[3] as number,
                completedEstimate: x[4] as number,
                leadTimeDays: x[5] as number,
                cycleTimeDays: x[6] as number,
                leadTimeForChangesDays: x[7] as number,
                percentile,
            }]))

            return {data: snapshots, ...queryResult}
        }

        return {data: undefined, ...queryResult}
    }

    function useAllTeamsPerformanceVelocity() {
        const filters = useFilters()

        const {data, ...queryResult} = useDataSet({
            dataset: 'issue',
            filters: {
                ...filters.getFilterSpec([FilterId.TimeRange, FilterId.IssueTypes]),
                completedOnly: true
            },
            select: [
                {name: 'team_id'},
                {name: 'issue_resolved_at_trunc', alias: 'ts', args: [{name: 'trunc_to', value: 'month'}]},
                {name: 'completed_issues_count'},
                {name: 'completed_estimate'},
            ]
        })

        if (data) {
            const result = Object.fromEntries(from(data.data.map(x => ({
                teamId: x[0] as string,
                ts: x[1] as number,
                completedIssues: x[2] as number,
                completedEstimate: x[3] as number,
            }))).groupBy(x => x.teamId)
                .select(x => {
                    const months = x.select(r => r.ts).distinct().count()
                    return {
                        teamId: x.key,
                        issueVelocity: months === 0 ? null : x.sum(r => r.completedIssues) / months,
                        spVelocity: months === 0 ? null : x.sum(r => r.completedEstimate) / months,
                    }
                })
                .toArray()
                .map(x => [x.teamId, x]))

            return {data: result, ...queryResult}
        }

        return {data: undefined, ...queryResult}
    }

}