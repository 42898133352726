import {Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow} from "@tremor/react";
import {useQuery} from "react-query";
import NoChartData from "@components/common/NoChartData";
import React from "react";
import {memberApi, MemberDto, MemberTeamDto} from "@services/memberApi";
import {FilterId, useEditableFilters} from "@components/filters/FiltersContext";
import {useDataSet} from "@utils/useDataSet";
import {from} from "linq-to-typescript";
import {toObjectEx} from "@utils/misc";
import {daysToDurationString} from "@components/common/DurationString";
import {useTenantNavigate} from "@services/tenants";

const percentile = 85

export function MembersListTab() {
    const filters = useEditableFilters()

    const {data: members, ...queryResult} = useQuery(['members', filters.teams.map(x => x.value)], {
        queryFn: () => memberApi.getMembers({
            teams: filters.teams.map(x => x.value)
        }),
        enabled: filters.isLoaded
    })

    const { data: prDetails, ...prQueryResult } = useDataSet({
        dataset: 'pull_request',
        filters: filters.getFilterSpec([FilterId.Teams, FilterId.TimeRange]),
        select: [
            {name: 'author_id'},
            {name: 'merged_pr_count'},
            {name: 'merged_pr_lifetime_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
        ],
    })

    const { data: commitDetails, ...commitQueryResult } = useDataSet({
        dataset: 'commit',
        filters: filters.getFilterSpec([FilterId.Teams, FilterId.TimeRange]),
        select: [
            {name: 'author_id'},
            {name: 'commit_count'}
        ],
    })

    const navigate = useTenantNavigate()

    if (!members)
        return <NoChartData title={"Участники"} {...queryResult} />

    if (!prDetails)
        return <NoChartData title={"Участники"} {...prQueryResult} />

    if (!commitDetails)
        return <NoChartData title={"Участники"} {...commitQueryResult} />

    const prs = toObjectEx(from(prDetails.data), x => x[0] as string, x => ({
        prCount: x[1] as number,
        prAvgLifetime: x[2] as number,
    }))

    const commits = toObjectEx(from(commitDetails.data), x => x[0] as string, x => ({
        count: x[1] as number,
    }))

    return <Table>
        <TableHead>
            <TableRow>
                <TableHeaderCell>Имя</TableHeaderCell>
                <TableHeaderCell>Команда</TableHeaderCell>
                <TableHeaderCell style={{textAlign: 'center'}}>Количество коммитов</TableHeaderCell>
                <TableHeaderCell style={{textAlign: 'center'}}>Количество PR</TableHeaderCell>
                <TableHeaderCell style={{textAlign: 'center'}}>Ср. время жизни PR</TableHeaderCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {members.map((member) =>
                <TableRow key={member.id}>
                    <TableCell style={{whiteSpace: 'break-spaces'}}>
                        <span style={{cursor: 'pointer'}} onClick={() => navigateToMember(member)}><b>{member.fullName}</b></span>
                    </TableCell>
                    <TableCell style={{whiteSpace: 'break-spaces'}}>
                        <MemberTeams teams={member.teams} />
                    </TableCell>
                    <TableCell style={{textAlign: 'center'}}>
                        {replaceZeroWithDash(commits[member.id]?.count ?? 0)}
                    </TableCell>
                    <TableCell style={{textAlign: 'center'}}>
                        {replaceZeroWithDash(prs[member.id]?.prCount ?? 0)}
                    </TableCell>
                    <TableCell style={{textAlign: 'center'}}>
                        {prs[member.id]?.prAvgLifetime
                            ? daysToDurationString(prs[member.id].prAvgLifetime)
                            : '-'}
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    </Table>

    function navigateToMember(member: MemberDto) {
        filters.setMemberId(member.id)
        navigate(`dashboard/members/stats`)
    }

    function replaceZeroWithDash(value: number) {
        return value === 0 ? '-' : value
    }

    function MemberTeams({teams}: {teams: MemberTeamDto[]}) {
        return <ul>
            {teams.map(t => <li key={t.id}>{t.name}</li>)}
        </ul>
    }
}