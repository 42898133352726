import React, {ReactNode, useEffect, useState} from 'react';
import {
    Card,
    Flex,
    Select,
    SelectItem,
    Title,
    Button,
    TextInput,
    MultiSelect,
    MultiSelectItem, DatePicker
} from "@tremor/react";
import {useQuery, useQueryClient} from "react-query";
import {from} from "linq-to-typescript";
import moment from "moment";
import {RiDeleteBinLine, RiSettings2Line} from "@remixicon/react";
import {useFilterValuesQuery} from "@services/queries";
import {QueryRequest} from "@services/dataSetApi";
import {workScopeForecastChartApi, WorkScopeForecastChartDto} from "@services/workScopeForecastChartApi";
import NoChartData from "@components/common/NoChartData";
import {toLocalISODateTimeString} from "@components/common/dateTimeStringUtils";
import {TableView} from "@components/TableView";
import {hoursToDurationString} from "@components/common/DurationString";
import ExclamationFilledIcon from "@components/common/ExclamationFilledIcon";
import BurnDownChart from "./BurnDownChart";

export default function BurnDownChartWithOptions() {
    const title = "Прогнозирование завершения выбранных задач"
    const [chartId, setChartId] = useState<string | undefined>();
    const [editChartId, setEditChartId] = useState<string | undefined>();

    const {data: charts, ...queryResult} = useQuery('getWorkScopeForecastCharts', {
        queryFn: () => workScopeForecastChartApi.list(),
    })

    const queryClient = useQueryClient()

    useEffect(() => {
        if (charts && charts.length && (chartId === undefined || !charts.some(x => x.id === chartId))) {
            setChartId(charts[0].id)
        }
    }, [chartId, charts]);

    if (!charts) {
        return <NoChartData title={title} {...queryResult} />
    }

    const chart = charts.find(x => x.id === chartId)
    const editChart = editChartId !== undefined && editChartId !== '_new' ? charts.find(x => x.id === editChartId) : undefined

    return (
        <>
            <Flex flexDirection="col" alignItems="start">
                <Title className="mb-2">{title}</Title>
                {charts.length > 0 && chartId && <Flex className="mb-3">
                    <Select placeholder="Укажите выборку задач для анализа..."
                        id="scopeSelector"
                        className="w-full"
                        value={editChartId || chartId}
                        onValueChange={handleChartSelectorValueChange}
                        enableClear={false}
                    >
                        <SelectItem style={{cursor: 'pointer'}} key='_new' value='_new'>&lt;<i>Добавить выборку задач...</i>&gt;</SelectItem>
                        {charts.map(x => <SelectItem key={x.id} value={x.id}>{x.name}</SelectItem>)}
                    </Select>
                    {chart && <Button onClick={() => setEditChartId(chart.id)} icon={RiSettings2Line} className="ml-3" variant='light'></Button>}
                </Flex>}
                {charts.length === 0 && <div>Нет настроенных выборок задач для прогнозирования завершения</div>}
                {chart && <SelectedBurndownChartDetails chart={chart} />}
            </Flex>

            {editChartId && <ForecastChartEditModal
                chart={editChart}
                onClose={() => setEditChartId(undefined)}
                onSave={handleSaveChart}
                onDelete={editChartId === "_new" ? undefined : handleDeleteChart}
            />}
        </>
    );

    function handleChartSelectorValueChange(value: string) {
        if (editChartId)
            return

        if (value === '_new') {
            setEditChartId(value)
            return
        }

        setChartId(value)
    }

    async function handleSaveChart(data: WorkScopeForecastChartDto) {
        if (!editChartId)
            return

        if (data.id === '_new') {
            const created = await workScopeForecastChartApi.create(data)
            await queryClient.invalidateQueries('getWorkScopeForecastCharts')
            setChartId(created.id)
        }
        else {
            await workScopeForecastChartApi.update(data)
            await queryClient.invalidateQueries('getWorkScopeForecastCharts')
            await queryClient.invalidateQueries({
                predicate: q => q.meta?.['workScopeForecastChartId'] === data.id
            })
        }

        setEditChartId(undefined)
    }

    async function handleDeleteChart() {
        if (!editChartId || editChartId === "_new")
            return

        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Удалить выборку?')) {
            return
        }

        await workScopeForecastChartApi.delete(editChartId)
        await queryClient.invalidateQueries('getWorkScopeForecastCharts')
        await queryClient.invalidateQueries({
            predicate: q => q.meta?.['workScopeForecastChartId'] === editChartId
        })

        setEditChartId(undefined)
    }
}

type SelectedBurndownChartDetailsProps = {
    chart: WorkScopeForecastChartDto
}

function SelectedBurndownChartDetails({chart}: SelectedBurndownChartDetailsProps) {
    const {valuesMap, ...filterValuesQueryResult} = useFilterValuesQuery(true)
    const [selectedIteration, setSelectedIteration] = useState<[Date, Date] | undefined>(undefined)

    // unset selected iteration when chart is changed
    useEffect(() => setSelectedIteration(undefined), [chart])

    const scopeStart = new Date(chart.start * 1000)
    const scopeFinishedAt = chart.scopeFinishedAt ? new Date(chart.scopeFinishedAt * 1000) : undefined

    const iterationCompletedQuery: QueryRequest = {
        dataset: 'issue',
        filters: {
            completedOnly: true,
            excludeCanceled: true,
            projectIds: chart.issueSelector.projects,
            epicIds: chart.issueSelector.epics,
            issueTypeIds: chart.issueSelector.issueTypes,
            timeRange: !selectedIteration ? undefined : {
                start: toLocalISODateTimeString(scopeStart > selectedIteration[0] ? scopeStart : selectedIteration[0]),
                end: toLocalISODateTimeString(scopeFinishedAt && scopeFinishedAt < selectedIteration[1] ? scopeFinishedAt : selectedIteration[1]),
            },
            includeItemsStartedBefore: true,
            includeItemsFinishedAfter: false
        },
        select: [
            {name: 'issue_id'},
            {name: 'issue_estimate'},
            {name: 'issue_key'},
            {name: 'issue_summary'},
            {name: 'issue_url'},
            {name: 'issue_created_at'},
            {name: 'issue_resolved_at'},
            {name: 'project_name'},
            {name: 'issue_type_name'},
            {name: 'issue_cycle_time_hrs'},
            {name: 'issue_lead_time_hrs'},
        ],
        orderBy: [
            {name: 'issue_created_at', desc: true}
        ],
        queryMeta: {
            workScopeForecastChartId: chart
        }
    }

    const iterationAddedQuery = {
        ...iterationCompletedQuery,
        filters: {
            ...iterationCompletedQuery.filters,
            completedOnly: false,
            includeItemsStartedBefore: false,
            includeItemsFinishedAfter: true,
        }
    }

    const remainingQuery = {
        ...iterationAddedQuery,
        filters: {
            ...iterationAddedQuery.filters,
            timeRange: !selectedIteration ? undefined : {
                start: toLocalISODateTimeString(scopeFinishedAt && scopeFinishedAt < selectedIteration[1] ? scopeFinishedAt : selectedIteration[1]),
                end: toLocalISODateTimeString(scopeFinishedAt && scopeFinishedAt < selectedIteration[1] ? scopeFinishedAt : selectedIteration[1])
            },
            includeItemsStartedBefore: true,
            includeItemsFinishedAfter: true,
        }
    }

    if (!valuesMap) {
        return <NoChartData title="Итерация" {...filterValuesQueryResult} />
    }

    return <>
        <Card className="mb-3">
            {chart.issueSelector.projects.length > 0 && <p>
                <b>Проекты</b>: {from(chart.issueSelector.projects).select(x => valuesMap.projects[x].name).toArray().join(', ')}
            </p>}
            {chart.issueSelector.epics.length > 0 && <p>
                <b>Эпики</b>: {from(chart.issueSelector.epics).select(x => valuesMap.epics[x].name).toArray().join(', ')}
            </p>}
            {chart.issueSelector.issueTypes.length > 0 && <p>
                <b>Типы задач</b>: {from(chart.issueSelector.issueTypes).select(x => valuesMap.issueTypes[x].name).toArray().join(', ')}
            </p>}
        </Card>

        <Card className="mb-3 h-[480px]"><BurnDownChart chart={chart} onSelectedIterationChanged={(v) => setSelectedIteration(v)} /></Card>

        {selectedIteration && <Card>
            <Title>Итерация {moment(selectedIteration[0]).format('DD.MM')} &ndash; {moment(selectedIteration[1]).format('DD.MM.YYYY')}</Title>
            <p className="text-lg mt-2">Завершенные задачи</p>
            <TableView
                query={iterationCompletedQuery}
                columns={[
                    {
                        name: 'issue_key',
                        formatter: (val, row) => <a rel="noreferrer" target="_blank" href={row[3] as string}>{val}</a>
                    },
                    {name: 'issue_summary'},
                    {name: 'issue_estimate'},
                    {name: 'issue_created_at'},
                    {name: 'issue_resolved_at'},
                    {name: 'project_name'},
                    {name: 'issue_type_name'},
                    {name: 'issue_cycle_time_hrs', formatter: v => hoursToDurationString(v as number)},
                    {name: 'issue_lead_time_hrs', formatter: v => hoursToDurationString(v as number)},
                ]}
            />

            <p className="text-lg mt-2">Добавленные задачи</p>
            <TableView
                query={iterationAddedQuery}
                columns={[
                    {
                        name: 'issue_key',
                        formatter: (val, row) => <a rel="noreferrer" target="_blank" href={row[3] as string}>{val}</a>
                    },
                    {name: 'issue_summary'},
                    {name: 'issue_estimate'},
                    {name: 'issue_created_at'},
                    {name: 'issue_resolved_at'},
                    {name: 'project_name'},
                    {name: 'issue_type_name'},
                    {name: 'issue_cycle_time_hrs', formatter: v => hoursToDurationString(v as number)},
                    {name: 'issue_lead_time_hrs', formatter: v => hoursToDurationString(v as number)},
                ]}
            />

            <p className="text-lg mt-2">Осталось выполнить</p>
            <TableView
                query={remainingQuery}
                columns={[
                    {
                        name: 'issue_key',
                        formatter: (val, row) => <a rel="noreferrer" target="_blank" href={row[3] as string}>{val}</a>
                    },
                    {name: 'issue_summary'},
                    {name: 'issue_estimate'},
                    {name: 'issue_created_at'},
                    {name: 'issue_resolved_at'},
                    {name: 'project_name'},
                    {name: 'issue_type_name'},
                    {name: 'issue_cycle_time_hrs', formatter: v => hoursToDurationString(v as number)},
                    {name: 'issue_lead_time_hrs', formatter: v => hoursToDurationString(v as number)},
                ]}
            />
        </Card>}
    </>
}

type ForecastChartEditModalProps = {
    chart?: WorkScopeForecastChartDto
    onClose: () => void
    onSave: (chart: WorkScopeForecastChartDto) => Promise<void>
    onDelete?: () => void
}

function useInput<T>(initial: T, validation?: (builder: ValidationRuleBuilder<T>) => ValidationRuleBuilder<T>) {
    const [value, setValue] = useState(initial)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const validate = validation?.(new ValidationRuleBuilder<T>()).build()

    return {
        value,
        setValue: handleSetValue,
        error: !!errorMessage,
        errorMessage,
        setErrorMessage,
        validate: () => {
            if (validate) {
                const errorMsg = validate(value)
                setErrorMessage(errorMsg)
                return !errorMsg
            }
            return true
        },
        getTremorInputProps: () => ({
            defaultValue: value,
            onValueChange: handleSetValue,
            error: !!errorMessage,
            errorMessage
        })
    }

    function handleSetValue(value: T) {
        setErrorMessage(undefined)
        setValue(value)
    }
}

function ForecastChartEditModal({chart, onClose, onSave, onDelete}: ForecastChartEditModalProps) {
    const {data: filterValues, valuesMap: filterValuesMap, ...filterValuesQueryResult} = useFilterValuesQuery(true)
    const name = useInput(chart?.name, x => x.required('Введите название'))
    const interval = useInput(chart?.interval || 'P1W', x => x.required('Выберите интервал'))
    const backlogStart = useInput(chart?.backlogStart ? new Date(chart.backlogStart * 1000) : undefined)
    const start = useInput(chart?.start !== undefined ? new Date(chart.start * 1000) : undefined, x => x.required('Укажите дату'))
    const scopeFinishedAt = useInput(chart?.scopeFinishedAt !== undefined ? new Date(chart.scopeFinishedAt * 1000) : undefined, x => x.must(v => !v || !start.value || v > start.value, 'Дата должна быть позже старта'))
    const [projects, setProjects] = useState(chart?.issueSelector.projects || [])
    const [epics, setEpics] = useState(chart?.issueSelector.epics || [])
    const [issueTypes, setIssueTypes] = useState(chart?.issueSelector.issueTypes || [])
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState<string | undefined>()

    useEffect(() => {
        if (interval.value === 'P7D') {
            interval.setValue('P1W')
        }
        if (interval.value === 'P14D') {
            interval.setValue('P2W')
        }
    }, [interval]);

    if (!filterValues || !filterValuesMap) {
        return <NoChartData title="Forecast Chart" {...filterValuesQueryResult} />
    }

    const allInputsToValidate = [name, interval, start, scopeFinishedAt]
    const id = chart?.id || '_new'

    return <>
        <div aria-hidden="true" className="fixed inset-0 w-full h-full bg-black/50 cursor-pointer z-40"></div>

        <div id="editChartModal"
            data-modal-backdrop="static"
            tabIndex={-1}
            aria-hidden="true"
            className="overflow-y-auto overflow-x-hidden fixed z-50 place-items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full grid"
        >
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            {chart ? 'Изменение выборки задач' : 'Добавление выборки задач'}
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Закрыть</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <div>
                            <label htmlFor="forecast-scope-name" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Название выборки <b>*</b></label>
                            <TextInput disabled={saving} {...name.getTremorInputProps()} id="forecast-scope-name" placeholder='Введите название выборки...' className="mx-auto" />
                        </div>

                        <div className="mt-3">
                            <label htmlFor="forecast-scope-start" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Дата начала наполнения бэклога</label>
                            <DatePicker disabled={saving} defaultValue={backlogStart.value} onValueChange={backlogStart.setValue} id="forecast-scope-start" placeholder="Не указано" className="mx-auto w-full" />
                        </div>

                        <div className="mt-3">
                            <label htmlFor="forecast-scope-start" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Дата начала проекта <b>*</b></label>
                            <InputValidationWrapper errorMessage={start.errorMessage}>
                                <DatePicker disabled={saving} defaultValue={start.value} onValueChange={start.setValue} id="forecast-scope-start" placeholder="Укажите дату начала проекта..." className="mx-auto w-full" />
                            </InputValidationWrapper>
                        </div>

                        <div className="mt-3">
                            <label htmlFor="forecast-scope-issue-types" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Интервал <b>*</b></label>
                            <InputValidationWrapper errorMessage={interval.errorMessage} iconMarginRight={8}>
                                <Select
                                    disabled={saving}
                                    id="forecast-scope-issue-types"
                                    className={"mt-1"}
                                    value={interval.value}
                                    onValueChange={interval.setValue}
                                    placeholder="Выберите интервал итерации..."
                                >
                                    <SelectItem value="P1W">1 неделя</SelectItem>
                                    <SelectItem value="P2W">2 недели</SelectItem>
                                    <SelectItem value="P1M">1 месяц</SelectItem>
                                    <SelectItem value="P2M">2 месяца</SelectItem>
                                    <SelectItem value="P3M">квартал</SelectItem>
                                </Select>
                            </InputValidationWrapper>
                        </div>

                        <div className="mt-3">
                            <label htmlFor="forecast-scope-projects" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Проекты</label>
                            <MultiSelect
                                disabled={saving}
                                id="forecast-scope-projects"
                                className={"mt-1"}
                                value={projects}
                                onValueChange={setProjects}
                                placeholderSearch=""
                                placeholder="Все проекты"
                            >
                                {filterValues.projects.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
                            </MultiSelect>
                        </div>

                        <div className="mt-3">
                            <label htmlFor="forecast-scope-epics" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Эпики</label>
                            <MultiSelect
                                disabled={saving}
                                id="forecast-scope-epics"
                                className={"mt-1"}
                                value={epics}
                                onValueChange={setEpics}
                                placeholderSearch=""
                                placeholder="Все эпики"
                            >
                                {filterValues.epics.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
                            </MultiSelect>
                        </div>

                        <div className="mt-3">
                            <label htmlFor="forecast-scope-issue-types" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Типы задач</label>
                            <MultiSelect
                                disabled={saving}
                                id="forecast-scope-issue-types"
                                className={"mt-1"}
                                value={issueTypes}
                                onValueChange={setIssueTypes}
                                placeholderSearch=""
                                placeholder="Все типы задач"
                            >
                                {filterValues.issueTypes.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
                            </MultiSelect>
                        </div>

                        {chart && <div className="mt-3">
                            <label htmlFor="forecast-scope-end" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Дата окончания</label>
                            <InputValidationWrapper errorMessage={scopeFinishedAt.errorMessage} iconMarginRight={10}>
                                <DatePicker disabled={saving} defaultValue={scopeFinishedAt.value} onValueChange={scopeFinishedAt.setValue} id="forecast-scope-end" placeholder="(не указано)" className="mx-auto w-full" />
                            </InputValidationWrapper>
                        </div>}

                        {serverError && <p className="text-sm text-red-500 mt-4">{serverError}</p>}

                    </div>
                    <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <div className="flex items-center gap-4">
                            <Button loading={saving} variant='primary' onClick={handleSave} className="min-w-28">
                                Сохранить
                            </Button>
                            <Button disabled={saving} variant='secondary' onClick={onClose} className="min-w-28">
                                Отмена
                            </Button>
                        </div>
                        {onDelete && <Button icon={RiDeleteBinLine} disabled={saving} variant='secondary' color="red" onClick={onDelete} className="min-w-28">
                            Удалить
                        </Button>}
                    </div>
                </div>
            </div>
        </div>
    </>

    async function handleSave() {
        if (!allInputsToValidate.reduce((a, x) => x.validate() && a, true))
            return

        setSaving(true)
        setServerError(undefined)

        try {
            await onSave({
                id: id,
                name: name.value!,
                backlogStart: backlogStart.value ? backlogStart.value.getTime() / 1000 : undefined,
                start: start.value!.getTime() / 1000,
                interval: interval.value!,
                issueSelector: {
                    projects: projects,
                    epics: epics,
                    issueTypes: issueTypes,
                },
                scopeFinishedAt: scopeFinishedAt.value === undefined ? undefined : scopeFinishedAt.value.getTime() / 1000,
                savedChartMaxDate: undefined,
            })
        }
        catch (err) {
            if (err instanceof Error) {
                setServerError(err.message)
            }
        }
        finally {
            setSaving(false)
        }
    }
}

type InputValidationWrapperProps = {
    children: ReactNode
    errorMessage?: string
    iconMarginRight?: number
}

function InputValidationWrapper({errorMessage, iconMarginRight = 2.5, children}: InputValidationWrapperProps) {
    return <>
        <div className="relative items-center flex w-full">
            {children}
            {!!errorMessage && <ExclamationFilledIcon className={`text-red-500 shrink-0 h-5 w-5 absolute right-0 flex items-center mr-${iconMarginRight}`} />}
        </div>
        {errorMessage && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>}
    </>
}

class ValidationRuleBuilder<T> {
    private rules: Array<(v: T) => string | undefined> = []

    public build(): (v: T) => string | undefined {
        return (v: T) => {
            for (const rule of this.rules) {
                const errorMsg = rule(v)
                if (errorMsg) {
                    return errorMsg
                }
            }
        }
    }

    public must(predicate: (v: T) => boolean, msg: string) {
        this.rules.push(x => predicate(x) ? undefined : msg);
        return this
    }

    public required(msg?: string) {
        return this.must(x => x !== undefined && x !== null && (typeof x !== 'string' || !!x), msg || 'Требуется значение')
    }
}