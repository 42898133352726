import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import moment from "moment";
import createTrend from "trendline";
import {intersect} from "mathjs";
import {renderToStaticMarkup} from "react-dom/server";
import NormalDistribution from "normal-distribution";
import {from} from "linq-to-typescript";
import {ECharts} from "echarts";
import {useQuery} from "react-query";
import {SelectChangedPayload} from "echarts/types/src/util/types";
import {roundToSignificantDigits} from "@utils/numericUtils";
import {workScopeForecastChartApi, WorkScopeForecastChartDataWorkSeriesDto, WorkScopeForecastChartDto} from "@services/workScopeForecastChartApi";
import {EChartsReact} from "@components/common/EChartsReact";
import {EChartsReactCore} from "@components/common/EChartsReact/EChartsReactCore";
import NoChartData from "@components/common/NoChartData";

type WorkDataPoint = WorkScopeForecastChartDataWorkSeriesDto & {
    workDoneCum: number
    workAddedCum: number
    workLeftExceptAdded: number
    workAddedCumNeg: number
    intervalEnd: number
}

interface BurnDownChartProps {
    chart: WorkScopeForecastChartDto;
    onSelectedIterationChanged?: (iteration?: [Date, Date]) => void
    style?: CSSProperties
}

export default function BurnDownChart({chart, onSelectedIterationChanged, ...props}: BurnDownChartProps) {
    const {data: dataResponse, ...queryResult} =
        useQuery(['getWorkScopeForecastChartData', chart.id], {
            queryFn: () => workScopeForecastChartApi.getData(chart.id),
            meta: {
                workScopeForecastChartId: chart.id
            },
        })

    const chartRef = useRef<EChartsReactCore>(null);

    const [selectedIterationIndex, setSelectedIterationIndex] = useState(-1)

    // useEffect(() => {
    //     onSelectedIterationChanged?.(undefined)
    // }, [dataResponse, onSelectedIterationChanged])

    useEffect(() => {
        const chart = chartRef.current?.getEchartsInstance()
        if (!chart)
            return

        chart.dispatchAction({
            type: 'unselect',
            seriesName: barsSeriesName,
        });

        if (selectedIterationIndex >= 0) {
            chart.dispatchAction({
                type: 'select',
                seriesName: barsSeriesName,
                dataIndex: selectedIterationIndex
            });
        }
    }, [selectedIterationIndex]);

    const dataRef = useRef<WorkDataPoint[] | undefined>(undefined)

    if (!dataResponse || !dataResponse.workSeries.length) {
        return <NoChartData title="Burn Down Chart" {...queryResult} isEmpty={dataResponse && (!dataResponse.workSeries.length)} />
    }

    const iterationDuration = moment.duration(dataResponse.chart.interval)

    const data = dataRef.current = dataResponse.workSeries.reduce((p, x) => {
        const prevVal = p[p.length - 1]
        const prevAddedCum = prevVal?.workAddedCum || 0
        const prevDoneCum = prevVal?.workDoneCum || 0
        p.push({
            ...x,
            workAddedCum: prevAddedCum + x.workAdded,
            workDoneCum: prevDoneCum + x.workDone,
            workLeftExceptAdded: dataResponse.workInitial - (prevDoneCum + x.workDone),
            workAddedCumNeg: -(prevAddedCum + x.workAdded),
            intervalEnd: moment.unix(x.intervalStart).add(iterationDuration).unix(),
        })
        return p
    }, [] as WorkDataPoint[])

    data.unshift({
        workAddedCumNeg: 0,
        workAddedCum: 0,
        workDoneCum: 0,
        workLeftExceptAdded: dataResponse.workInitial,
        workDone: 0,
        workAdded: 0,
        intervalStart: moment.unix(data[0].intervalStart).subtract(iterationDuration).unix(),
        intervalEnd: data[0].intervalStart,
        intervalStartLocal: 'Старт',
        issuesAdded: 0,
        issuesDone: 0,
    })

    const minDate = data[0].intervalEnd
    let maxDate = data[data.length - 1].intervalEnd

    let trend1Data: {x: number, y: number}[] = []
    let trend2Data: {x: number, y: number}[] = []

    let distData: Array<{ts: number, values: {pfd: number, cdf: number}}> | undefined = undefined

    if (data.length > 1) {
        const trend1 = createTrend(data as any, 'intervalEnd', 'workLeftExceptAdded')
        trend1Data = [
            {x: minDate, y: trend1.calcY(minDate)},
            {x: maxDate, y: trend1.calcY(maxDate)}
        ]

        const trend2 = createTrend(data as any, 'intervalEnd', 'workAddedCumNeg')
        trend2Data = [
            {x: minDate, y: trend2.calcY(minDate)},
            {x: maxDate, y: trend2.calcY(maxDate)}
        ]

        const intersection = intersect(
            [trend1Data[0].x, trend1Data[0].y], [trend1Data[1].x, trend1Data[1].y],
            [trend2Data[0].x, trend2Data[0].y], [trend2Data[1].x, trend2Data[1].y])

        const trendLinesConverge = intersection && intersection[0] > minDate

        if (trendLinesConverge) {
            maxDate = intersection[0] as number
            trend1Data[1] = {x: maxDate, y: trend1.calcY(maxDate)}
            trend2Data[1] = {x: maxDate, y: trend2.calcY(maxDate)}
        }

        // calculate probability chart data
        const avgVelocity = from(data).skip(1).average(x => x.workDone)
        const avgAdded = from(data).skip(1).average(x => x.workAdded)
        const workLeft = data[data.length - 1].workLeftExceptAdded + data[data.length - 1].workAddedCum

        const stdDevVelocity2 = from(data).skip(1).average(x => (x.workDone - avgVelocity) * (x.workDone - avgVelocity))
        const stdDevAdded2 = from(data).skip(1).average(x => (x.workAdded - avgAdded) * (x.workAdded - avgAdded))

        distData = []

        let iterationEnd = data[data.length - 1].intervalEnd

        distData.push({
            ts: iterationEnd,
            values: {
                pfd: 0,
                cdf: 0,
            }
        })

        let forwardIteration = 0
        do {
            iterationEnd = moment.unix(iterationEnd).add(iterationDuration).unix()
            forwardIteration++
            const mean = forwardIteration * (avgVelocity - avgAdded)
            const stdDev = Math.sqrt(forwardIteration * (stdDevVelocity2 + stdDevAdded2))
            const dist = new NormalDistribution(mean, stdDev)
            distData.push({
                ts: iterationEnd,
                values: {
                    cdf: 1 - dist.cdf(workLeft),
                    pfd: (1 - dist.cdf(workLeft)) - distData[distData.length - 1].values.cdf,
                }
            })
        }
        while (iterationEnd < maxDate || (forwardIteration < 15 && (1 - distData[distData.length - 1].values.cdf) > 0.001))

        maxDate = moment.unix(iterationEnd).add(iterationDuration).unix()
    }

    const ticks = data.map(x => x.intervalEnd)
    for (let date = moment.unix(data[data.length - 1].intervalEnd).add(iterationDuration); date.unix() < maxDate; date.add(iterationDuration))
        ticks.push(date.unix())

    const barsSeriesName = 'Осталось SP'
    const trendSeriesName = 'Линейный тренд'
    const probCumSeriesName = 'Вероятн. завершения в любую дату до'
    const probDiffSeriesName = 'Вероятн. завершения в эту дату'

    const performSetSelectedIterationIndex = (index: number) => {
        setSelectedIterationIndex(index)
        const data = dataRef.current
        if (onSelectedIterationChanged && data) {
            onSelectedIterationChanged(index >= 0 ? [new Date(data[index].intervalStart * 1000), new Date(data[index].intervalEnd * 1000)] : undefined)
        }
    }

    return (
        <EChartsReact
            style={props.style}
            onChartReady={(chart: ECharts) => {
                chart.on('selectchanged', function (params: any) {
                    const payload = params as SelectChangedPayload

                    if (!payload.isFromClick) {
                        return
                    }

                    if (payload.fromActionPayload.seriesIndex < 1 || payload.fromActionPayload.seriesIndex > 2)
                        return

                    if (payload.isFromClick && payload.fromActionPayload.dataIndexInside !== undefined) {
                        performSetSelectedIterationIndex(payload.fromAction === 'select' ? payload.fromActionPayload.dataIndexInside : -1)
                    }
                })
            }}
            innerRef={chartRef}
            className="h-full w-full"
            option={{
                grid: {
                    left: 5,
                    right: 5,
                    top: 30,
                    bottom: 40,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'time',
                        axisLabel: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false,
                            lineStyle: {
                                color: 'green'
                            }
                        }
                    },
                    {
                        type: 'value',
                        show: true,
                        position: 'right',
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            formatter: (x: number) => `${Math.round(x * 100)}%`
                        },
                    }
                ],
                tooltip: [
                    {
                        trigger: 'axis',
                        formatter: (args) => {
                            args = args as Array<any>

                            const includesBars = args.some(x => x.seriesName === barsSeriesName)

                            if (includesBars) {
                                const value = data[args[0].dataIndex as number]

                                if (value.intervalStart < dataResponse.chart.start) {
                                    return renderToStaticMarkup(<>
                                        <b>Начальное состояние</b>
                                        <div>Всего: {dataResponse.workInitial} SP</div>
                                    </>)
                                }

                                return renderToStaticMarkup(<>
                                    <p><b>{moment.unix(value.intervalStart).format('DD.MM')}&nbsp;&ndash;&nbsp;{moment.unix(value.intervalEnd).format('DD.MM.YYYY')}</b></p>
                                    <div>Выполнено за итерацию: {value.workDone} SP</div>
                                    <div>Добавлено за итерацию: {value.workAdded} SP</div>
                                    <div>Осталось выполнить: {value.workLeftExceptAdded + value.workAddedCum} SP</div>
                                </>)
                            }

                            const trendCross = args.find(x => x.seriesName === trendSeriesName && x.seriesType === 'scatter')
                            if (trendCross) {
                                return renderToStaticMarkup(<>
                                    <p>Вероятная дата завершения согласно линейному тренду:<br />
                                        <b className="text-nowrap">{moment(((trendCross.data as Array<any>)[0] as Date)).format('DD.MM.YYYY')}</b>
                                    </p>
                                </>)
                            }

                            const probCum = args.find(x => x.seriesName === probCumSeriesName && (x.value as any[])[1] as number > 0)
                            const probDiff = args.find(x => x.seriesName === probDiffSeriesName && (x.value as any[])[1] as number > 0)

                            if (probCum || probDiff) {
                                const series: any = probCum ? probCum : probDiff!;
                                const endDate = (series.data as any[])[0] as Date
                                return renderToStaticMarkup(<>
                                    <p><b>{moment(endDate).format('DD.MM.YYYY')}</b></p>
                                    {probCum && <p>Вероятность завершения до этой даты: <b>{roundToSignificantDigits((probCum.data as any[])[1] as number * 100, 2)}%</b></p>}
                                    {probDiff && <p>Вероятность завершения в эту дату: <b>{roundToSignificantDigits((probDiff.data as any[])[1] as number * 100, 2)}%</b></p>}
                                </>)
                            }

                            return ''
                        }
                    }
                ],
                legend: {
                    data: [barsSeriesName, trendSeriesName, probCumSeriesName, probDiffSeriesName],
                    selectedMode: false,
                },
                series: [
                    {
                        name: 'Placeholder',
                        type: 'bar',
                        stack: 'x',
                        itemStyle: {
                            borderColor: 'transparent',
                            color: 'transparent'
                        },
                        emphasis: {
                            itemStyle: {
                                borderColor: 'transparent',
                                color: 'transparent'
                            }
                        },
                        data: data.map(x => [new Date(x.intervalEnd * 1000), x.workLeftExceptAdded >= 0 ? 0 : x.workLeftExceptAdded]),
                    },
                    {
                        name: barsSeriesName,
                        type: 'bar',
                        stack: 'x',
                        data: data.map(x => [new Date(x.intervalEnd * 1000), x.workLeftExceptAdded >= 0 ? x.workLeftExceptAdded : 0]),
                        z: 10,
                        markLine: {
                            data: ticks.map(x => ({
                                xAxis: x * 1000,
                            })),
                            symbol: ['none', 'none'],
                            animation: false,
                            silent: true,
                            label: {
                                formatter: x => x.value === minDate * 1000 ? "Старт" : moment.unix(x.value as number / 1000).format('DD.MM'),
                                position: 'start',
                                offset: [0, 10],
                                //fontSize: 10,
                                rotate: 60,
                                align: 'right',
                                baseline: 'bottom'
                            },
                            z: 1,
                            lineStyle: {
                                type: 'solid',
                                color: '#44444420',
                            }
                        },
                        select: {
                            itemStyle: {
                                color: '#1887FF',
                                borderWidth: 0,
                                borderColor: 'transparent',
                                //shadowColor: 'black',
                                //shadowBlur: 4,
                            },
                        },
                        selectedMode: 'single',
                    },
                    {
                        name: barsSeriesName,
                        type: 'bar',
                        z: 10,
                        stack: 'x',
                        data: data.map(x => [new Date(x.intervalEnd * 1000), x.workLeftExceptAdded >= 0 ? -x.workAddedCum : -x.workLeftExceptAdded - x.workAddedCum]),
                        select: {
                            itemStyle: {
                                color: '#1887FF',
                                borderWidth: 0,
                                borderColor: 'transparent',
                                //shadowColor: 'black',
                                //shadowBlur: 4,
                            },
                        },
                        selectedMode: 'single',
                    },
                    {
                        name: trendSeriesName,
                        type: 'line',
                        data: trend1Data.map(v => [new Date(v.x * 1000), v.y]),
                        symbol: 'none',
                        silent: true,
                        z: 11,
                        markLine: {
                            z: 20,
                            data: [
                                {
                                    xAxis: trend1Data[1].x * 1000,
                                }
                            ],
                            symbol: ['none', 'none'],
                            animation: false,
                            silent: true,
                            label: {
                                show: false,
                                formatter: x => moment.unix(x.value as number / 1000).format('DD.MM'),
                                position: 'start',
                                offset: [0, 5],
                            },
                            lineStyle: {
                            }
                        }
                    },
                    {
                        name: trendSeriesName,
                        z: 11,
                        type: 'line',
                        data: trend2Data.map(v => [new Date(v.x * 1000), v.y]),
                        symbol: 'none',
                        silent: true,
                    },
                    {
                        name: trendSeriesName,
                        type: 'scatter',
                        symbolSize: 7,
                        data: [[new Date(trend1Data[1].x * 1000), trend1Data[1].y]],
                        label: {
                            show: true,
                            formatter: x => moment(((x.value as Array<any>)[0] as Date)).format('DD.MM'),
                            position: 'top'
                        }
                    },
                    {
                        name: probDiffSeriesName,
                        type: 'line',
                        data: !distData ? [] : distData.filter(x => x.values.pfd !== undefined).map(x => [
                            new Date(x.ts * 1000),
                            x.values.pfd > 0 ? x.values.pfd : 0
                        ]),
                        yAxisIndex: 1
                    },
                    {
                        name: probCumSeriesName,
                        type: 'line',
                        data: !distData ? [] : distData.map(x => [
                            new Date(x.ts * 1000),
                            x.values.cdf > 0 ? x.values.cdf : 0
                        ]),
                        yAxisIndex: 1
                    },
                ]
            }}
        />
    )
}
