import {useDataSet} from "@utils/useDataSet";
import {FilterId, IFiltersContext, useFilters} from "@components/filters/FiltersContext";

const percentile = 85

export type PrMeasureSnapshotData = {
    prAverageLifetimeDays: number
    percentile: number
}

export type PrMeasureDynamicsData = {
    percentile: number
    items: PrMeasureDynamicsDataItem[]
}

export type PrMeasureDynamicsDataItem = {
    ts: number
    prAverageLifetimeDays: number
}

export type FiltersOverride = {
    filters: FilterId[]
    validate?: (filters: IFiltersContext) => boolean
}

export function usePrMeasureSnapshotDataSet(filtersOverride?: FiltersOverride) {
    const filters = useFilters()

    console.log(filters)
    console.log(filtersOverride?.filters)

    const {data, ...queryResult} = useDataSet({
        dataset: 'pull_request',
        filters: {
            ...filters.getFilterSpec(filtersOverride ? filtersOverride.filters : [FilterId.TimeRange, FilterId.Teams])
        },
        isEmpty: filtersOverride?.validate ? !filtersOverride.validate(filters) : false,
        select: [
            {name: 'merged_pr_lifetime_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
        ],
    })

    if (data && data.data.length) {
        const snapshot: PrMeasureSnapshotData = {
            prAverageLifetimeDays: data.data[0][0] as number,
            percentile: percentile as number,
        }

        return {data: snapshot, ...queryResult}
    }

    return {data: undefined, isEmpty: data && !data.data.length, ...queryResult}
}

export function usePrMeasureDynamicsDataSet(filtersOverride?: FiltersOverride) {
    const filters = useFilters()

    const {data, ...queryResult} = useDataSet({
        dataset: 'pull_request',
        filters: {
            ...filters.getFilterSpec(filtersOverride ? filtersOverride.filters : [FilterId.TimeRange, FilterId.Teams]),
            includeItemsStartedBefore: false,
            includeItemsFinishedAfter: true
        },
        isEmpty: filtersOverride?.validate ? !filtersOverride.validate(filters) : false,
        select: [
            {name: 'pr_merged_at_trunc', alias: 'ts', args: [{name: 'trunc_to', value: 'month'}]},
            {name: 'merged_pr_lifetime_days_n_percentile', args: [{name: 'percentile', value: percentile}]},
        ]
    })

    if (data && data.data.length > 0) {
        const ds: PrMeasureDynamicsData = {
            percentile,
            items: data.data.map(x => ({
                ts: x[0] as number,
                prAverageLifetimeDays: x[1] as number,
            } as PrMeasureDynamicsDataItem))
        }

        return {data: ds, isEmpty: false, ...queryResult}
    }

    return {data: undefined, isEmpty: data && data.data.length === 0, ...queryResult}
}