import {api} from "./api";

export type SprintDto = {
    sprintId: string
    sprintName: string
    from: number
    to: number
    closedAt?: number
    sprintGroupId?: string | null
    sprintGroupName?: string | null
}

export type SprintGroupDto = {
    id: string
    name: string
}

export type SprintIssueDto = {
    sprintId: string
    issueId: string
    issueStatusId: string
    issueStatusName: string
    issueCompleted: boolean
    estimate: number
    issueStatusSortOrder: number
    issueTypeId: string
    issueTypeName: string
    issueKey: string
    summary: string
    issueUrl: string | null
}

export class SprintApi {
    public async getSprints() {
        return await api.get<SprintDto[]>('sprints')
    }

    public async getSprintGroups() {
        return await api.get<SprintGroupDto[]>('sprints/groups')
    }

    public async getIssues(sprintId: string) {
        return await api.get<SprintIssueDto[]>(`sprints/${sprintId}/issues`)
    }

    public async getSprint(sprintId: string) {
        return await api.get<SprintDto>(`sprints/${sprintId}`)
    }
}

export const sprintApi = new SprintApi()
