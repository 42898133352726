import { useDataSet } from "@utils/useDataSet";
import { FilterId, IFiltersContext, useFilters } from "@components/filters/FiltersContext";

export type CommitCountSnapshotData = {
    totalCommits: number;
};

export type CommitCountDynamicsData = {
    items: CommitCountDynamicsDataItem[];
};

export type CommitCountDynamicsDataItem = {
    ts: number;
    commitCount: number;
};

export type FiltersOverride = {
    filters: FilterId[];
    validate?: (filters: IFiltersContext) => boolean;
};

export function useCommitCountSnapshotDataSet(filtersOverride?: FiltersOverride) {
    const filters = useFilters();

    const { data, ...queryResult } = useDataSet({
        dataset: 'commit',
        filters: {
            ...filters.getFilterSpec(filtersOverride ? filtersOverride.filters : [FilterId.TimeRange, FilterId.Member])
        },
        isEmpty: filtersOverride?.validate ? !filtersOverride.validate(filters) : false,
        select: [
            { name: 'commit_count' },
        ],
    });

    if (data && data.data.length) {
        const totalCommits = data.data[0][0] as number;
        const snapshot: CommitCountSnapshotData = {
            totalCommits,
        };

        return { data: snapshot, ...queryResult };
    }

    return { data: undefined, isEmpty: data && !data.data.length, ...queryResult };
}

export function useCommitCountDynamicsDataSet(filtersOverride?: FiltersOverride) {
    const filters = useFilters();

    const { data, ...queryResult } = useDataSet({
        dataset: 'commit',
        filters: {
            ...filters.getFilterSpec(filtersOverride ? filtersOverride.filters : [FilterId.TimeRange, FilterId.Member]),
        },
        isEmpty: filtersOverride?.validate ? !filtersOverride.validate(filters) : false,
        select: [
            { name: 'committed_date_trunc', alias: 'ts', args: [{ name: 'trunc_to', value: 'week' }] },
            { name: 'commit_count' },
        ]
    });

    if (data && data.data.length > 0) {
        const ds: CommitCountDynamicsData = {
            items: data.data.map(x => ({
                ts: x[0] as number,
                commitCount: x[1] as number,
            } as CommitCountDynamicsDataItem))
        };

        return { data: ds, isEmpty: false, ...queryResult };
    }

    return { data: undefined, isEmpty: data && data.data.length === 0, ...queryResult };
}