import './App.css';
import React, {useContext, useEffect, useState} from 'react';
import {Route, Navigate, Outlet, createRoutesFromElements, createBrowserRouter, RouterProvider, useRouteError} from "react-router-dom"
import {QueryClient, QueryClientProvider} from 'react-query';
import {Flex} from "@tremor/react";
import {AimgerHeader} from "@components/AimgerHeader";
import DashboardPage from "@pages/DashboardPage";
import {AuthContext, AuthProvider} from "@components/AuthContext";
import {api, ApiRequestError, RedirectToAuthError} from "@services/api";
import {Redirect} from "@utils/Redirect";
import NotFoundPage from "@pages/NotFoundPage";
import {useTenant} from "@services/tenants";
import {AimgerVersion} from "@components/AimgerVersion";

const TenantMasterPage = () => {
    return <Flex className="h-full" flexDirection="col">
        <AimgerHeader />
        <main className="mt-1 flex-grow w-full h-full overflow-auto">
            <Outlet/>
        </main>
        <AimgerVersion />
    </Flex>
}

const NoTenant = () => {
    //return <Redirect to='https://aimger.ru' />
    return <Redirect to='/demo'/>
}

const RequireAuth = () => {
    const tenant = useTenant()
    const { user, setUser } = useContext(AuthContext)
    const [ error, setError ] = useState<string | null>(null)

    useEffect(() => {
        (async () => {
            if (!user) {
                try {
                    setUser(await api.getUser())
                    setError(null)
                } catch (err) {
                    if (err instanceof RedirectToAuthError) {
                        // ignore
                    } else if ((err as DOMException)?.name === 'AbortError') {
                        setError('Server request error (timeout)')
                    } else {
                        console.error(err)
                        setError('Server request error')
                    }
                }
            }
        })()
    }, [user, setUser, setError])

    if (user) {
        if (user.isAuthorized)
            return <Outlet/>
        else
            return <div>У вас нет доступа к <i>{tenant}</i>.</div>
    } else if (error) {
        return <div>{error}</div>
    } else {
        return <div>Аутентификация...</div>
    }
}

const ErrorBoundary = () => {
    let error = useRouteError();

    if (error instanceof RedirectToAuthError) {
        return <div>Redirecting to authentication provider...</div>;
    }

    console.error(error);

    const message = error instanceof Error
        ? error.message
        : 'Unknown error'

    return <div>Error occurred: {message}</div>;
}

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
            retry: (failureCount, error) => {
                if (failureCount > MAX_RETRIES) {
                    return false;
                }

                if (error instanceof ApiRequestError && HTTP_STATUS_TO_NOT_RETRY.includes(error.statusCode)) {
                    return false;
                }

                return true;
            },
        },
    }
})

const App = () => {
    const router = createBrowserRouter(createRoutesFromElements(
        <Route errorElement={<ErrorBoundary/>}>
            <Route index element={<NoTenant/>}/>
            <Route path=":tenant" element={<TenantMasterPage/>}>
                <Route element={<RequireAuth/>}>
                    <Route path="" element={<Navigate to='dashboard/overview' replace/>}/>
                    <Route path="dashboard" element={<Navigate to='overview' replace/>}/>
                    <Route path="dashboard/:section/:tab?" element={<DashboardPage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Route>
            </Route>
            <Route path="*" element={<NoTenant/>}/>
        </Route>
    ));

    return (
        <QueryClientProvider client={queryClient}>
        <AuthProvider>
            <RouterProvider router={router}/>
        </AuthProvider>
        </QueryClientProvider>
    )
}

export default App;
