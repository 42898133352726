import {Card, Col, Grid} from "@tremor/react";
import {DefectsReportedFixedChart} from "@components/charts/defects/DefectsReportedFixedChart";
import {DefectsReportedFixedCumChart} from "@components/charts/defects/DefectsReportedFixedCumChart";

export function TeamQualityTab() {
    return <Grid numItemsSm={1} numItemsLg={2} className="gap-3 mt-3">
        <Col numColSpan={1}>
            <Card style={{height: "30rem"}}>
                <DefectsReportedFixedChart />
            </Card>
        </Col>
        <Col numColSpan={1}>
            <Card style={{height: "30rem"}}>
                <DefectsReportedFixedCumChart />
            </Card>
        </Col>
    </Grid>
}