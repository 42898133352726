import {Bold, Metric, Text, Title} from "@tremor/react";
import {RiInformation2Line} from "@remixicon/react";
import Tooltip, {useTooltip} from "@tremor/react/dist/components/util-elements/Tooltip/Tooltip";
import {daysToDurationString} from "@components/common/DurationString";
import NoChartData from "@components/common/NoChartData";
import {FiltersOverride, usePrMeasureSnapshotDataSet} from "./dataSources/repoDataSources";
import {getKpiColorInv} from "@utils/misc";

type PrAverageLifetimeSnapshotViewProps = {
    filtersOverride?: FiltersOverride
}

export function PrAverageLifetimeSnapshotView({filtersOverride}: PrAverageLifetimeSnapshotViewProps) {
    const title = "Время жизни MR"
    const {data, ...queryResult} = usePrMeasureSnapshotDataSet(filtersOverride)
    const {tooltipProps, getReferenceProps} = useTooltip();

    if (!data || !data.prAverageLifetimeDays)
        return <NoChartData title={title} isEmpty={data && !data.prAverageLifetimeDays} {...queryResult} />

    const durationString = daysToDurationString(data.prAverageLifetimeDays)

    return (
        <>
            <Tooltip text={<div style={{width: 320}} className={"text-wrap"}>
                <span>с {data.percentile}% вероятностью следующие MR будут слиты менее чем за </span>
                <span className={"text-nowrap"}>{durationString}</span>
            </div> as any} {...tooltipProps} />
            <Title>{title}</Title>
            <Metric
                className={`${getKpiColorInv(data.prAverageLifetimeDays, 6 * 24, 12 * 24)} mt-1`}>{durationString}</Metric>
            <Text>или меньше потребовалось, чтобы по <Bold>{data.percentile}%</Bold> открытым Merge Request-ам <Bold>слить их</Bold> в target-ветку.
                <sup><span style={{baselineShift: 'super'}} ref={tooltipProps.refs.setReference} {...getReferenceProps}>
                    <RiInformation2Line style={{width: 16, height: 16, display: 'inline'}} />
                </span></sup>
            </Text>
            <Text className={"mt-3"}>Данная метрика соответствует времени, прошедшему с момента открытия Merge Request до момента его слияния в target-ветку.</Text>
        </>
    );
}

