import {SprintDto} from "@services/sprintApi";
import {BadgeDelta, Subtitle, Title} from "@tremor/react";
import {useDataSet} from "@utils/useDataSet";
import NoChartData from "@components/common/NoChartData";
import {EChartsReact} from "@components/common/EChartsReact";
import React from "react";
import {getWordInCase} from "@components/common/dateTimeStringUtils";
import {SeriesOption} from "echarts";
import {useEditableFilters} from "@components/filters/FiltersContext";

export type SprintVelocityChartProps = {
    sprintGroupId: string
    sprint: SprintDto
    byIssues: boolean
}

export function SprintVelocityChart({sprintGroupId, sprint, byIssues}: SprintVelocityChartProps) {
    const maxSprintsToShow = 12
    const movingAverageCount = 12
    const title = 'Velocity'
    const totalMeasure = byIssues ? 'total_issues_count' : 'total_estimate_sum'
    const completedMeasure = byIssues ? 'closed_issues_count' : 'closed_estimate_sum'
    const yAxisName = byIssues ? 'Задач' : 'Стори поинты (SP)'

    const filters = useEditableFilters()

    const {data: sprintsReversed, ...queryResult} = useDataSet({
        dataset: 'sprint',
        select: [
            {name: 'sprint_short_name', alias: 'sprint'},
            {name: totalMeasure, alias: 'total'},
            {name: completedMeasure, alias: 'completed'},
            {name: 'sprint_from'},
            {name: 'sprint_id'},
        ],
        filters: {
            sprintGroupId: sprintGroupId,
        },
        orderBy: [
            {name: 'sprint_from', desc: true},
        ],
        limit: maxSprintsToShow + movingAverageCount,
    })

    if (!sprintsReversed) {
        return <NoChartData title={title} {...queryResult} />
    }

    const allSprints = [...sprintsReversed.data].reverse()
    const sprints = allSprints.slice(-maxSprintsToShow)

    const ma = ([...sprintsReversed.data].reverse().reduce((acc, row, i, arr) => {
        acc.sum += row[2] as number
        if (acc.cnt < movingAverageCount) {
            acc.cnt++
        } else {
            acc.sum -= arr[i - movingAverageCount][2] as number
        }
        acc.points.push(acc.sum / acc.cnt)
        return acc
    }, {sum: 0, cnt: 0, points: []} as { sum: number, cnt: number, points: number[] })).points.slice(-maxSprintsToShow)

    const avgLineFormatter = (x: number) => byIssues
        ? `Velocity: {value|${Number(x.toFixed(1))}} ${getWordInCase('задача', Number(x.toFixed(1)), 'nominative')}/спринт`
        : `Velocity: {value|${Number(x.toFixed(1))}} SP/спринт`

    const selectedSprintIndex = sprints.findIndex(x => x[4] === sprint.sprintId)

    const series: SeriesOption[] = [
        {
            type: 'bar',
            stack: 'x',
            name: 'Завершено',
            data: sprints.map(x => x[2] as number),
            markLine: {
                data: [
                    {
                        type: 'average',
                    }
                ],
                symbol: ['none', 'arrow'],
                animation: false,
                silent: true,
                label: {
                    formatter: x => avgLineFormatter(x.value as number),
                    position: 'insideEndTop',
                    rich: {
                        value: {
                            fontWeight: 'bolder',
                        },
                    }
                },
            }
        },
        {
            type: 'bar',
            name: 'Осталось',
            stack: 'x',
            data: sprints.map(x => (x[1] as number) - (x[2] as number)),
        },
        {
            type: 'line',
            data: ma,
            showSymbol: false,
            smooth: true,
            name: 'Velocity (ср.)',
            lineStyle: {
                width: 3
            }
        },
    ];

    if (selectedSprintIndex >= 0) {
        series.push({
            type: 'scatter',
            symbolSize: 11,
            z: 4,
            data: [[selectedSprintIndex, sprints[selectedSprintIndex][2] as number]],
            tooltip: {
                show: false
            },
        })
    }

    let increase = selectedSprintIndex >= 0 && ma[selectedSprintIndex]
        ? 100.0 * (sprints[selectedSprintIndex][2] as number - ma[selectedSprintIndex]) / ma[selectedSprintIndex]
        : 0
    increase = Number(increase.toFixed(1))

    return <div className="flex flex-col h-full items-start">
        <div className={'flex w-full flex-row justify-between'}>
            <div className={'flex flex-row items-baseline'}>
                <Title>{title}</Title>
                {sprint.sprintGroupName ? <Subtitle className={"ml-2"}>{sprint.sprintGroupName}</Subtitle> : null}
            </div>
            {increase && <BadgeDelta tooltip={'Отклонение от средней Velocity'}
                                     deltaType={increase > 0 ? "increase" : "decrease"}
                                     isIncreasePositive={true}
                                     size="xs">
                {increase}%
            </BadgeDelta>}
        </div>
        <EChartsReact
            onEvents={{
                'click': (params: any) => {
                    const sprintIndex = params.seriesIndex === 0 || params.seriesIndex === 1 ? params.dataIndex : -1
                    if (sprintIndex >= 0 && sprints) {
                        const sprintId = sprints[sprintIndex][4]
                        filters.setSprintId(sprintId as string)
                    }
                }
            }}
            className="flex-grow h-full w-full"
            option={{
                grid: {
                    left: 25,
                    right: 5,
                    top: 20,
                    bottom: 10,
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: sprints.map(x => x[0] as string),
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: yAxisName,
                        nameLocation: 'middle',
                        nameGap: 30,
                    }
                ],
                series: series,
                tooltip: [
                    {
                        trigger: 'axis',
                        valueFormatter: (x) => `${Number((x as number).toFixed(2))}`
                    }
                ],
            }}
        />
    </div>
}