import { Flex, Title } from "@tremor/react";
import NoChartData from "@components/common/NoChartData";
import { EChartsReact } from "@components/common/EChartsReact";
import { MarkLine1DDataItemOption } from "echarts/types/src/component/marker/MarkLineModel";
import {
    CommitCountDynamicsDataItem,
    FiltersOverride,
    useCommitCountDynamicsDataSet
} from "./dataSources/commitDataSources";

type CommitCountDynamicsChartProps = {
    filtersOverride?: FiltersOverride;
};

export function CommitCountDynamicsChart({ filtersOverride }: CommitCountDynamicsChartProps) {
    const title = "Количество коммитов в динамике";
    const { data: response, ...queryResult } = useCommitCountDynamicsDataSet(filtersOverride);

    if (!response)
        return <NoChartData title={title} {...queryResult} />;

    const sortedItems = [...response.items].sort((a, b) => a.ts - b.ts);
    const data = sortedItems.map(x => ([
        new Date(x.ts * 1000),
        x.commitCount
    ]));

    const weeklyAverage = calculateWeeklyAverage(sortedItems);

    return (
        <Flex flexDirection="col" className="h-full" alignItems="start">
            <Title className="my-0">{title}</Title>
            <EChartsReact
                className="mt-3 h-full w-full"
                option={{
                    grid: {
                        left: 50,
                        right: 10,
                        top: 10,
                        bottom: 20
                    },
                    xAxis: {
                        type: 'time',
                    },
                    yAxis: {
                        type: 'value',
                        name: 'Количество коммитов'
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: function (params: any) {
                            const date = new Date(params[0].value[0]);
                            const commitCount = params[0].value[1];
                            return `${date.toLocaleDateString()}: ${commitCount} коммитов`;
                        }
                    },
                    series: [
                        {
                            name: 'Количество коммитов',
                            type: 'bar',
                            data: data,
                            itemStyle: {
                                color: 'rgba(60, 130, 246, 0.8)'
                            },
                            markLine: {
                                symbol: [],
                                animation: false,
                                silent: true,
                                data: [
                                    {
                                        yAxis: weeklyAverage,
                                        label: {
                                            formatter: `Среднее количество коммитов в неделю: ${Number(weeklyAverage.toFixed(1))}`,
                                            position: 'insideStartTop'
                                        }
                                    } as MarkLine1DDataItemOption,
                                ]
                            }
                        },
                    ],
                }}
            />
        </Flex>
    );
}

function calculateWeeklyAverage(items: CommitCountDynamicsDataItem[]): number {
    const totalCommits = items.reduce((sum, item) => sum + item.commitCount, 0);
    return totalCommits / items.length;
}

function getIncrease(weeklyAverage: number, items: CommitCountDynamicsDataItem[]) {
    if (items.length === 0) {
        return { increase: undefined, baseValue: undefined };
    }

    const lastMonthItems = items.slice(-4); // Last 4 weeks
    const lastMonthAverage = calculateWeeklyAverage(lastMonthItems);

    return {
        baseValue: weeklyAverage,
        increase: Math.round(1000 * (lastMonthAverage - weeklyAverage) / weeklyAverage) / 10
    };
}