import {IEnumerable} from "linq-to-typescript";

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getKpiColorInv(value: number, midFrom: number, midTo: number, prefix: string = 'text-'): string {
    if (value <= midFrom)
        return `${prefix}green-400`

    if (value <= midTo)
        return `${prefix}yellow-500`

    return `${prefix}red-400`
}

export function getKpiColor(value: number, midFrom: number, midTo: number, prefix: string = 'text-'): string {
    if (value >= midTo)
        return `${prefix}green-400`

    if (value >= midFrom)
        return `${prefix}yellow-500`

    return `${prefix}red-400`
}

export function toObjectEx<TSource, TKey extends keyof any, TVal>(src: IEnumerable<TSource>, keySelector: (x: TSource) => TKey, valSelector: (x: TSource) => TVal): Record<TKey, TVal> {
    return src.aggregate({} as Record<TKey, TVal>, (acc, x) => {
        acc[keySelector(x)] = valSelector(x)
        return acc
    })
}