import {Card, Col, Grid, Title} from "@tremor/react";
import {PrLifetimeDynamicsChart} from "@components/charts/valueDelivery/PrMeasureDynamicsChart";
import {FilterId, useFilters} from "@components/filters/FiltersContext";
import {PrAverageLifetimeSnapshotView} from "@components/charts/valueDelivery/PrMeasureSnapshotView";
import {FiltersOverride} from "@components/charts/valueDelivery/dataSources/repoDataSources";
import {TableView} from "@components/TableView";
import React from "react";
import NoChartData from "@components/common/NoChartData";
import { CommitCountSnapshotView } from "@components/charts/valueDelivery/CommitCountSnapshotView";
import { CommitCountDynamicsChart } from "@components/charts/valueDelivery/CommitCountDynamicsChart";

export function MemberStatsTab() {
    const filtersOverride: FiltersOverride = {
        filters: [FilterId.TimeRange, FilterId.Member],
        validate: x => !!x.memberId
    }

    const filters = useFilters()
    
    if (!filters.isLoaded) {
        return <NoChartData title={null} isLoading={true}/>
    }
    
    if (!filters.memberId) {
        return <div>Выберите участника</div>
    }

    return <Grid numItems={1} className="gap-3 mt-3 xl:grid-cols-4">
        <Col>
            <Card className={"h-full"}>
                <PrAverageLifetimeSnapshotView filtersOverride={filtersOverride} />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <PrLifetimeDynamicsChart measure="prAverageLifetime" filtersOverride={filtersOverride} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <CommitCountSnapshotView filtersOverride={filtersOverride} />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <CommitCountDynamicsChart filtersOverride={filtersOverride} />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card>
                <Title>Пулреквесты</Title>
                <PrTableView />
            </Card>
        </Col>
    </Grid>

    function PrTableView() {
        const filters = useFilters()

        return <TableView
            query={{
                dataset: 'pull_request',
                select: [
                    {name: 'title'},
                    {name: 'web_url'},
                    {name: 'author_id'},
                    {name: 'repository_name'},
                    {name: 'created_at'},
                    {name: 'is_merged'},
                    {name: 'merged_at'},
                    {name: 'source_branch'},
                    {name: 'target_branch'},
                ],
                filters: {
                    ...filters.getFilterSpec([FilterId.TimeRange, FilterId.Member]),
                    excludeCanceled: true,
                },
                orderBy: [
                    //{name: 'is_merged'},
                    {name: 'merged_at', desc: true},
                ]
            }}
            columns={[
                {
                    name: 'title',
                    displayName: "PR",
                    formatter: (val, row) => <a rel="noreferrer" target="_blank" href={row[1] as string}><b>{val}</b></a>
                },
                {name: 'created_at'},
                {name: 'merged_at'},
                {name: 'repository_name'},
            ]}
            pageSize={20}
        />;
    }
}