import {api} from "./api";

export type TeamDto = {
    id: string
    name: string
    active: boolean
    members: TeamMemberDto[]
}

export type TeamMemberDto = {
    id: string
    name: string
    active: boolean
}

export class TeamApi {
    public async getTeams() {
        return await api.get<TeamDto[]>(`teams`)
    }
}

export const teamApi = new TeamApi()
