import Tooltip, {useTooltip} from "@tremor/react/dist/components/util-elements/Tooltip/Tooltip";
import NoChartData from "@components/common/NoChartData";
import {Metric, Title} from "@tremor/react";
import React from "react";
import {useDataSet} from "@utils/useDataSet";
import {FilterId, useFilters} from "@components/filters/FiltersContext";
import {RiInformation2Line} from "@remixicon/react";

export function OngoingDefectsSnapshotView() {
    const title = "Средний возраст незакрытых дефектов"
    const {tooltipProps, getReferenceProps} = useTooltip();
    const filters = useFilters()
    const {data, ...queryResult} = useDataSet({
        dataset: 'issue',
        filters: {
            ...filters.getFilterSpec([FilterId.Projects, FilterId.DefectTypes, FilterId.Teams]),
            includeItemsStartedBefore: true,
            includeItemsFinishedAfter: true,
            openedOnly: true,
            defectsOnly: true,
        },
        select: [
            {name: 'issues_count'},
            {name: 'total_estimate'},
            {name: 'estimated_issues_count'},
        ],
    })

    if (!data || !data.data.length)
        return <NoChartData title={title} {...queryResult} isEmpty={data && !data.data.length} />

    const [issuesCount, totalEstimate, estimatedIssuesCount] = data.data[0] as number[]

    return <>
        <Title>{title}</Title>
        <Metric className="text-tremor-metric mt-1">{issuesCount}</Metric>

        <Tooltip text={`Учтены только оцененные дефекты (${estimatedIssuesCount})`} {...tooltipProps} />
        <Title className="mt-3">SP в процессе {<span className={"text-nowrap"}>
            <sup>
                <span style={{baselineShift: 'super'}} ref={tooltipProps.refs.setReference} {...getReferenceProps}>
                    <RiInformation2Line style={{width: 16, height: 16, display: 'inline'}} />
                </span>
            </sup>
        </span>}</Title>
        <Metric className="text-tremor-metric">{totalEstimate}<span className={"text-tremor-default font-normal"}> SP</span></Metric>
    </>
}