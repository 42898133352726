import {NavigateOptions, NavLink, NavLinkProps, To, useNavigate, useParams} from "react-router-dom";
import * as React from "react";

export const useTenant = () => useParams().tenant as string

// useNavigate() scoped to the current tenant.
// Paths should be specified from root, for relative navigation use useNavigate()
export function useTenantNavigate() {
    const navigate = useNavigate()
    const tenant = useTenant()

    return (to: To, options?: NavigateOptions) =>
    {
        to = to.toString()
        if (!to.startsWith('/'))
            to = '/' + to;

        navigate(`/${tenant}${to}`, options)
    }
}

export function TenantNavLink({to, ...props}: NavLinkProps & React.RefAttributes<HTMLAnchorElement>) {
    const tenant = useTenant()

    to = to.toString()
    if (!to.startsWith('/'))
        to = '/' + to;

    return <NavLink to={`/${tenant}${to}`} {...props} />
}