import qs from "qs";
import {api} from "./api";
import {DataSetReadRequestDto} from "./sharedApiModels";

export type LeadTimeRequest = DataSetReadRequestDto & {
    percentile?: number
}

export type LeadTimeDistributionRequest = LeadTimeRequest & {
    bins?: number
}

export type DistributionSeriesResponse = {
    step: number
    items: DistributionSeriesItemDto[]
}

export type DistributionSeriesItemDto = {
    from: number
    qty: number
}

export class IssuesDataSetApi {
    public async getLeadTimeDist(request?: LeadTimeDistributionRequest) {
        const query = request && qs.stringify(request, {allowDots: true})
        return await api.get<DistributionSeriesResponse>(`issuesDataSet/leadTime/dist`, query)
    }

    public async getCycleTimeDist(request?: LeadTimeDistributionRequest) {
        const query = request && qs.stringify(request, {allowDots: true})
        return await api.get<DistributionSeriesResponse>(`issuesDataSet/cycleTime/dist`, query)
    }

    public async getLeadTimeForChangesDist(request?: LeadTimeDistributionRequest) {
        const query = request && qs.stringify(request, {allowDots: true})
        return await api.get<DistributionSeriesResponse>(`issuesDataSet/leadTimeForChanges/dist`, query)
    }
}

export const issuesDataSetApi = new IssuesDataSetApi()