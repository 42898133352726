import {Card, Col, Grid} from "@tremor/react";
import {CycleTimeSnapshotView, LeadTimeForChangesSnapshotView, LeadTimeSnapshotView} from "@components/charts/valueDelivery/IssueMeasureSnapshotView";
import {LeadTimeDynamicsChart} from "@components/charts/valueDelivery/IssueMeasureDynamicsChart";
import LeadTimeDistributionChart from "@components/charts/valueDelivery/LeadTimeDistributionChart";
import {PrAverageLifetimeSnapshotView} from "@components/charts/valueDelivery/PrMeasureSnapshotView";
import {PrLifetimeDynamicsChart} from "@components/charts/valueDelivery/PrMeasureDynamicsChart";
import {ReleaseFrequencySnapshotView} from "@components/charts/valueDelivery/ReleaseFrequencySnapshotView";
import {StatusBreakdownSummaryTable} from "@components/charts/flowAnalisys/StatusBreakdownSummaryTable";
import {CumulativeFlowDiagramChart} from "@components/charts/flowAnalisys/CumulativeFlowDiagramChart";
import {ReleaseFrequencyDynamicsChart} from "@components/charts/valueDelivery/ReleaseFrequencyDynamicsChart";
import { StatusTimeBreakdownSummaryChart } from "@components/charts/flowAnalisys/StatusTimeBreakdownSummaryChart";

export function TeamVelocityTab() {
    return <Grid numItems={1} className="gap-3 mt-3 xl:grid-cols-4">
        <Col>
            <Card className={"h-full"}>
                <LeadTimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="leadTime" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"leadTime"} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <CycleTimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="cycleTime" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"cycleTime"} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <PrAverageLifetimeSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <PrLifetimeDynamicsChart measure="prAverageLifetime" />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <LeadTimeForChangesSnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <LeadTimeDynamicsChart measure="leadTimeForChanges" />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "25rem"}}>
                <LeadTimeDistributionChart measure={"leadTimeForChanges"} />
            </Card>
        </Col>
        <Col>
            <Card className={"h-full"}>
                <ReleaseFrequencySnapshotView />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card style={{height: "25rem"}}>
                <ReleaseFrequencyDynamicsChart measure="releaseFrequency" />
            </Card>
        </Col>
        <Col className="xl:col-span-1">
            <Card className={"h-full"}>
                <StatusTimeBreakdownSummaryChart />
            </Card>
        </Col>
        <Col className="xl:col-span-3">
            <Card>
                <StatusBreakdownSummaryTable interval={'day'} />
            </Card>
        </Col>
        <Col className="xl:col-span-4">
            <Card style={{height: "30rem"}}>
                <CumulativeFlowDiagramChart compact={true} />
            </Card>
        </Col>
    </Grid>
}