import {Callout, Title} from "@tremor/react";
import {from} from "linq-to-typescript";
import {getDimensionColor} from "@utils/colorPaletteUtils";
import {SprintDto, SprintIssueDto} from "@services/sprintApi";
import {LinearBreakUpSection, LinearBreakUpView} from "@components/common/LinearBreakUpView";
import NoChartData from "@components/common/NoChartData";

export type SprintWorkProgressViewProps = {
    sprint: SprintDto
    issues: SprintIssueDto[]
    byIssues: boolean
}

export function SprintWorkProgressView({sprint, issues, byIssues}: SprintWorkProgressViewProps) {
    const byStatusSections = from(issues)
        .where(x => byIssues || x.estimate > 0)
        .select(x => ({displayStatus: getIssueStatus(x), ...x}))
        .groupBy(x => x.displayStatus.id)
        .orderBy(x => x.first().displayStatus.sortOrder)
        .select(g => ({
            name: g.first().displayStatus.name,
            value: byIssues ? g.count() : g.sum(i => i.estimate),
            color: g.first().displayStatus.color,
        } as LinearBreakUpSection))
        .toArray()

    return <div>
        <Title className={"mb-3"}>Прогресс по задачам</Title>
        <LinearBreakUpView sections={byStatusSections} />
    </div>

    function getIssueStatus(issue: SprintIssueDto): {id: string, name: string, sortOrder: string, color: string} {
        if (sprint!.closedAt) {
            return issue.issueCompleted
                ? {id: '0', name: 'Завершено', sortOrder: "0", color: '#9FE080'}
                : {id: '1', name: 'Перенесено', sortOrder: "1", color: '#FF7070'}
        } else {
            return {
                id: issue.issueStatusId,
                name: issue.issueStatusName,
                sortOrder: (issue.issueStatusSortOrder + 100000).toString(),
                color: getDimensionColor('issue_status_name', issue.issueStatusName)
            }
        }
    }
}