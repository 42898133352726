import {Metric, Text, Title} from "@tremor/react";
import NoChartData from "@components/common/NoChartData";
import {useReleaseFreqSnapshotDataSet, useReleaseFreqDynamicsDataSet} from "./dataSources/cicdDataSources";
import {getKpiColor} from "@utils/misc";
import {DoraBadge} from "@components/common/DoraBadge";

export function ReleaseFrequencySnapshotView() {
    const title = "Release Frequency"
    //const {data, ...queryResult} = useReleaseFreqSnapshotDataSet()
    const {data: snapshot, ...snapshotQueryResult} = useReleaseFreqSnapshotDataSet()
    const {data: response, ...queryResult} = useReleaseFreqDynamicsDataSet()

    if (!snapshot)
        return <NoChartData title={title} {...snapshotQueryResult} />

    if (!response)
        return <NoChartData title={title} {...queryResult} />

    const value = snapshot.jobCount / response.items.length

    const frequencyString = Number(value?.toFixed(1)).toString()

    return (
        <div>
            <Title>{title}<DoraBadge /></Title>
            <Metric
                className={`${getKpiColor(value, 1, 2)} mt-1`}>{frequencyString}</Metric>
            <Text>релизов <b>в месяц</b> было совершено за указанный промежуток.
            </Text>
            <Text className={"mt-3"}>Данная метрика соответствует количеству релизных джобов за месяц.</Text>
        </div>
    );
}

