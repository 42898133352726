import {useDataSet} from "@utils/useDataSet";
import NoChartData from "@components/common/NoChartData";
import {DataSetAccessor} from "@services/dataSetApi";
import {defaultFormatters} from "@components/TableView";
import {Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow} from "@tremor/react";
import {FilterId, useEditableFilters} from "@components/filters/FiltersContext";
import {useTenantNavigate} from "@services/tenants";

type SprintRow = {
    sprintId: string
    sprintName: string
    sprintFrom: number
    sprintTo: number
    sprintClosedAt: number | null
    totalIssuesCount: number
    closedIssuesCount: number
    remainingIssuesCount: number
    groupId: string | null
    groupName: string | null
}

export function SprintsListTab() {
    const filters = useEditableFilters()
    const navigate = useTenantNavigate()

    const {data: sprintRows, ...sprintQueryResult} = useDataSet({
        dataset: 'sprint',
        select: [
            {name: 'sprint_id'},
            {name: 'sprint_short_name'},
            {name: 'sprint_from'},
            {name: 'sprint_to'},
            {name: 'sprint_closed_at'},
            {name: 'closed_issues_count'},
            {name: 'total_issues_count'},
            {name: 'remaining_issues_count'},
            {name: 'closed_estimate_sum'},
            {name: 'total_estimate_sum'},
            {name: 'remaining_estimate_sum'},
            {name: 'sprint_group_id'},
            {name: 'sprint_group_name'},
        ],
        filters: filters.getFilterSpec([FilterId.SprintGroup]),
        orderBy: [
            { name: 'sprint_from', desc: true }
        ],
    })

    if (!sprintRows) {
        return <NoChartData title={"Спринты"} {...sprintQueryResult} />
    }

    const sprintsDs = new DataSetAccessor(sprintRows)

    const sprints = sprintsDs.getRows()
        //.where(x => !!x['sprint_closed_at'])
        .select(x => ({
            sprintId: x['sprint_id'] as string,
            sprintName: x['sprint_short_name'] as string,
            sprintFrom: x['sprint_from'] as number,
            sprintTo: x['sprint_to'] as number,
            sprintClosedAt: x['sprint_closed_at'] as (number | null),
            totalIssuesCount: x['total_issues_count'] as number,
            closedIssuesCount: x['closed_issues_count'] as number,
            remainingIssuesCount: x['remaining_issues_count'] as number,
            groupId: x['sprint_group_id'] as string | null,
            groupName: x['sprint_group_name'] as string | null,
        } as SprintRow))
        .where(x => x.totalIssuesCount > 0)
        //.orderByDescending(x => x.sprintFrom)
        .toArray()

    const instantFormatter = (val: number) => defaultFormatters.Instant(val, [])

    return <>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Название спринта</TableHeaderCell>
                    <TableHeaderCell>Проект/команда</TableHeaderCell>
                    <TableHeaderCell>Дата начала</TableHeaderCell>
                    <TableHeaderCell>Дата окончания</TableHeaderCell>
                    <TableHeaderCell>Статус</TableHeaderCell>
                    <TableHeaderCell>Всего задач</TableHeaderCell>
                    <TableHeaderCell>Закрыто задач</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sprints.map(x => <TableRow key={x.sprintId}>
                    <TableCell><b style={{cursor: 'pointer'}} onClick={() => navigateToSprint(x.sprintId)}>{x.sprintName}</b></TableCell>
                    <TableCell>{x.groupName}</TableCell>
                    <TableCell>{instantFormatter(x.sprintFrom)}</TableCell>
                    <TableCell>{instantFormatter(x.sprintTo)}</TableCell>
                    <TableCell>{!!x.sprintClosedAt ? 'Завершен' : 'В процессе'}</TableCell>
                    <TableCell>{x.totalIssuesCount}</TableCell>
                    <TableCell>{x.closedIssuesCount}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </>

    function navigateToSprint(sprintId: string) {
        filters.setSprintId(sprintId)
        navigate(`dashboard/sprints/single`)
    }
}
