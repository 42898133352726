import {Title} from "@tremor/react";
import React, {TableHTMLAttributes} from "react";
import {TableView} from "@components/TableView";
import {FilterId, useFilters} from "@components/filters/FiltersContext";
import {getKpiColorInv} from "@utils/misc";

type OngoingIssuesTableViewProps = TableHTMLAttributes<HTMLTableElement> & {
    defects?: boolean,
    pageSize?: number
}

export function OngoingIssuesTableView({defects, pageSize, ...props}: OngoingIssuesTableViewProps) {
    const filters = useFilters()

    return <>
        <Title>{`Незавершенные ${defects ? 'дефекты' : 'задачи'}`}</Title>
        <TableView
            pageSize={pageSize || 50}
            query={{
                dataset: 'issue',
                filters: {
                    ...filters.getFilterSpec([FilterId.Projects, defects ? FilterId.DefectTypes : FilterId.IssueTypes, FilterId.Teams]),
                    includeItemsStartedBefore: true,
                    includeItemsFinishedAfter: true,
                    openedOnly: true,
                    defectsOnly: !!defects,
                },
                select: [
                    {name: 'issue_id'},
                    {name: 'issue_key'},
                    {name: 'issue_summary'},
                    {name: 'issue_url'},
                    {name: 'issue_created_at'},
                    {name: 'project_name'},
                    {name: 'issue_type_name'},
                    {name: 'issue_estimate'},
                    {name: 'issue_status_name'},
                    {name: 'issue_age_hrs'},
                ],
                orderBy: [
                    {name: 'issue_created_at', desc: true}
                ]
            }}
            columns={[
                {
                    name: 'issue_key',
                    formatter: (val, row) => <a rel="noreferrer" target="_blank" href={row[3] as string}>{val}</a>
                },
                {name: 'issue_summary'},
                {name: 'issue_created_at'},
                {name: 'project_name'},
                {name: 'issue_type_name'},
                {name: 'issue_estimate'},
                {name: 'issue_status_name'},
                {
                    name: 'issue_age_hrs',
                    displayName: 'Возраст (д.)',
                    formatter: val => <span className={getKpiColorInv((val as number)/24, 20, 50)}>{Number(((val as number)/24).toFixed(1))}</span>
                },
            ]}
            {...props}
        />
    </>
}